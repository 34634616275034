import { useRef, useState } from "react";
import TemplateConfig from "../../../core/config/TemplateConfig";
import VariablesConfig from "../../../core/config/VariablesConfig";
import HomeWrapperComponent from "../../home/ui/HomeWrapperComponent"
import CardComponent from "../../shared/ui/CardComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import TextComponent from "../../shared/ui/TextComponent";
import OptionItemComponent from "./OptionItemComponent";
import { ProductAddReqest } from "../model/ProductAddRequest";
import { useNavigate, useParams } from "react-router-dom";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import PhotoItemComponent from "./PhotoItemComponent";
import { useTranslation } from "react-i18next";
import ListItem from "../../market/ui/ListItemComponent";
import { toast } from "react-toastify";
import UploadProductService from "../service/UploadProductService";
import InputItem from "../../market/ui/InputItemComponent";
import CheckItem from "../../market/ui/CheckItemComponent";
import { Product } from "../../market/model/ProductsResponse";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import ProgressBarComponent from "./ProgressBarComponent";

const ProductAddComponent = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { params } = useParams();
    const editProduct = !params ? null : JSON.parse(decodeURIComponent(params)) as Product;
    const decodedProduct = !editProduct || customerDataStore.customerData!.customer.id !== editProduct.owner ? null : JSON.parse(editProduct.tags) as ProductAddReqest;
    const data = useRef<ProductAddReqest>({
        category: decodedProduct ? decodedProduct.category : '',
        subcategory: decodedProduct ? decodedProduct.subcategory : '',
        brand: decodedProduct ? decodedProduct.brand : '',
        model: decodedProduct ? decodedProduct.model : '',
        year: decodedProduct ? decodedProduct.year : '',
        wheel: decodedProduct ? decodedProduct.wheel : '',
        body: decodedProduct ? decodedProduct.body : '',
        engine: decodedProduct ? decodedProduct.engine : '',
        drive: decodedProduct ? decodedProduct.drive : '',
        tranmission: decodedProduct ? decodedProduct.tranmission : '',
        engineSize: decodedProduct ? decodedProduct.engineSize : '',
        mileage: decodedProduct ? decodedProduct.mileage : '',
        cleared: decodedProduct ? decodedProduct.cleared : false,
        option: decodedProduct ? decodedProduct.option : [],
        photo: [],
        description: decodedProduct ? decodedProduct.description : '',
        color: decodedProduct ? decodedProduct.color : '',
        price: decodedProduct ? decodedProduct.price : '',
        trade: decodedProduct ? decodedProduct.trade : false,
        exchange: decodedProduct ? decodedProduct.exchange : false,
        order: decodedProduct ? decodedProduct.order : false,
        city: decodedProduct ? decodedProduct.city : '',
        owner: decodedProduct ? decodedProduct.owner : false,
        newcar: decodedProduct ? decodedProduct.newcar : false,
        projectCar: decodedProduct ? decodedProduct.projectCar : false,
    });
    const [localModels, setLocalModels] = useState<null | any[]>(null);
    const [localEditPhotos, setLocalEditPhotos] = useState<string[] | undefined>(decodedProduct ? decodedProduct.photo as string[] : undefined);

    // template
    const [page, setPage] = useState(0);
    const [reload, setReload] = useState(false);
    const [showSubcategory, setShowSubcategory] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const stepNames = ["category", "marka", "charac", "option", "photo", "descrip", "finish"];
    const template = [
        [
            {
                type: 'list', name: "category", value: data.current.category, items: VariablesConfig.category, onClick: (value: string) => {
                    data.current.category = value;
                    setReload(!reload);
                    data.current.subcategory = '';
                    if (value !== VariablesConfig.category[VariablesConfig.category.length - 1]) setPage(page + 1);
                    else setShowSubcategory(true);
                }
            },
            {
                type: 'list', name: "subcategory", value: data.current.subcategory, items: VariablesConfig.commercSubcategory, hide: data.current.category !== VariablesConfig.category[VariablesConfig.category.length - 1], onClick: (value: string) => {
                    data.current.subcategory = value;
                    setShowSubcategory(false);
                    setPage(page + 1);
                },
                execShow: showSubcategory
            }
        ],
        [
            {
                type: 'list', name: "brand", value: data.current.brand, items: data.current.category === VariablesConfig.category[0] || data.current.category === ''
                    ? VariablesConfig.brands
                    : data.current.category === VariablesConfig.category[1]
                        ? VariablesConfig.motoBrands
                        : VariablesConfig.commercBrands,
                showBrandIcon: data.current.category !== VariablesConfig.category[2],
                onClick: (value: string) => {
                    data.current.brand = value;
                    setLocalModels(
                        data.current.category === VariablesConfig.category[0] || data.current.category === ''
                            ? VariablesConfig.brands.find(item => item.label === value)!.model
                            : data.current.category === VariablesConfig.category[1]
                                ? VariablesConfig.motoBrands.find(item => item.label === value)!.model
                                : VariablesConfig.commercBrands.find(item => item.label === value)!.model
                    );
                    setShowModel(true);
                }
            },
            { type: 'list', name: "model", value: data.current.model, items: localModels, onClick: (value: string) => data.current.model = value, execShow: showModel },
            { type: 'list', name: "year", value: data.current.year, items: VariablesConfig.year, onClick: (value: string) => data.current.year = value },
            { type: 'list', name: "body", value: data.current.body, items: VariablesConfig.body, onClick: (value: string) => data.current.body = value, hide: data.current.category !== VariablesConfig.category[0] },
            { type: 'list', name: "engine", value: data.current.engine, items: VariablesConfig.engine, onClick: (value: string) => data.current.engine = value, hide: data.current.category !== VariablesConfig.category[0] },
            { type: 'list', name: "drive", value: data.current.drive, items: VariablesConfig.drive, onClick: (value: string) => data.current.drive = value, hide: data.current.category !== VariablesConfig.category[0] },
            {
                type: 'list', name: "tranmission", value: data.current.tranmission, items: VariablesConfig.transmission, onClick: (value: string) => {
                    data.current.tranmission = value;
                    if (!isEmpty(data.current.brand) && !isEmpty(data.current.model) && !isEmpty(data.current.body) && !isEmpty(data.current.engine) && !isEmpty(data.current.drive) && !isEmpty(data.current.year)) {
                        setShowModel(false);
                        setPage(page + 1);
                    }
                }, hide: data.current.category !== VariablesConfig.category[0]
            }
        ],
        [
            { type: 'list', name: "wheel", value: data.current.wheel, items: VariablesConfig.wheel, onClick: (value: string) => data.current.wheel = value, hide: data.current.category !== VariablesConfig.category[0] },
            { type: 'list', name: "engineSize", value: data.current.engineSize, items: VariablesConfig.engineSize, onClick: (value: string) => data.current.engineSize = value, hide: data.current.category !== VariablesConfig.category[0] },
            { type: 'input', text: 'mileage', value: data.current.mileage, onChange: (value: string) => data.current.mileage = value, filter: "price", maxLength: 15 },
            { type: 'check', value: data.current.cleared, text: "cleared", onChange: (value: boolean) => data.current.cleared = value },
            {
                type: 'list', name: "color", value: data.current.color, items: VariablesConfig.color, onClick: (value: string) => {
                    data.current.color = value;
                    if (!isEmpty(data.current.wheel) && !isEmpty(data.current.engineSize) && !isEmpty(data.current.mileage)) setPage(page + 1);
                }
            }
        ],
        [
            { type: 'option' }
        ],
        [
            { type: 'photo' }
        ]
    ];
    const templateLastStage = [

        { type: 'list', name: "city", value: data.current.city, items: VariablesConfig.city, onClick: (value: string) => data.current.city = value },
        { type: 'input', text: 'description', value: data.current.description, onChange: (value: string) => data.current.description = value, filter: undefined, maxLength: 500 },
        { type: 'input', text: 'price', value: data.current.price, onChange: (value: string) => data.current.price = value, filter: "price", maxLength: 15 },
        { type: 'check', value: data.current.trade, text: "trade", onChange: (value: boolean) => data.current.trade = value },
        { type: 'check', value: data.current.exchange, text: "exchange", onChange: (value: boolean) => data.current.exchange = value },
        { type: 'check', value: data.current.order, text: "order", onChange: (value: boolean) => data.current.order = value },
        { type: 'check', value: data.current.owner, text: "owner", onChange: (value: boolean) => data.current.owner = value },
        { type: 'check', value: data.current.newcar, text: "newcar", onChange: (value: boolean) => data.current.newcar = value }
    ];
    if (customerDataStore.customerData!.customer.admin_permission === '1') templateLastStage.push({ type: 'check', value: data.current.projectCar, text: "projectCar", onChange: (value: boolean) => data.current.projectCar = value });
    template.push(templateLastStage);

    // changePage
    function isEmpty(value: string) { return value.length < 1 }
    async function changePage() {
        if (page === 0 && (
            isEmpty(data.current.category) ||
            (data.current.category === VariablesConfig.category[VariablesConfig.category.length - 1] && isEmpty(data.current.subcategory))
        )) {
            toast(t('toast.inputError'), { type: "error" });
            return;
        }
        else if (page === 1 && (isEmpty(data.current.brand) || isEmpty(data.current.model) || isEmpty(data.current.year))) {
            toast(t('toast.inputError'), { type: "error" });
            return;
        }
        else if (page === 4 && data.current.photo.length < 1) {
            toast(t('toast.photoError'), { type: "error" });
            return;
        }
        else if (page === 5) {
            if (isEmpty(data.current.price)) {
                toast(t('toast.priceError'), { type: "error" });
                return;
            }
            if (isEmpty(data.current.brand) || isEmpty(data.current.model) || isEmpty(data.current.year) || isEmpty(data.current.price) || data.current.photo.length < 1) {
                toast(t('toast.inputError'), { type: "error" });
                return;
            }
            var result = await UploadProductService(data.current, decodedProduct && editProduct ? editProduct : undefined);
            if (!result) return;
        }
        setShowSubcategory(false);
        setShowModel(false);
        setPage(page + 1);
    }
    return (
        <HomeWrapperComponent>
            <div style={{ maxWidth: TemplateConfig.mobileMaxWidth, margin: '0 auto' }}>
                <TextComponent type={TemplateConfig.isMobile ? 'large' : 'extraLarge'}>
                    {t('addPage.title')}
                </TextComponent>
                <RetirementComponent />
                <CardComponent padding>
                    {
                        customerDataStore.customerData?.customer.admin_permission === '1'
                            ? <TextComponent>{t('admin.permission')}</TextComponent>
                            : <>
                                <ProgressBarComponent count={stepNames.length} current={page} />
                                <RetirementComponent />
                                <TextComponent type='boldMedium'>{page + 1}. {t(`addPage.stageNames.${stepNames[page]}`)}</TextComponent>
                                <RetirementComponent />
                                {
                                    page === stepNames.length - 1
                                        ? <TextComponent>{t('addPage.finishText')}</TextComponent>
                                        : template[page].map((field: any, index) =>
                                            <div key={index}>
                                                {
                                                    !field.hide &&
                                                    <>
                                                        {
                                                            field.type === 'list'
                                                                ? ListItem(field, t)
                                                                : field.type === 'input'
                                                                    ? InputItem(field, t)
                                                                    : field.type === 'check'
                                                                        ? CheckItem(field, t)
                                                                        : field.type === 'option'
                                                                            ? <OptionItemComponent onChange={(value: any) => data.current.option = value} init={data.current.option} t={t} />
                                                                            : <PhotoItemComponent
                                                                                init={data.current.photo as File[]}
                                                                                onChange={(files: File[]) => data.current.photo = files}
                                                                                editData={localEditPhotos}
                                                                                onEditUpload={() => setLocalEditPhotos(undefined)}
                                                                                t={t}
                                                                            />
                                                        }
                                                        {
                                                            template[page].filter((row: any) => !row.hide).length - 1 !== index
                                                            && <RetirementComponent size={10} />
                                                        }
                                                    </>
                                                }
                                            </div>
                                        )
                                }
                                <RetirementComponent />
                                {
                                    page === stepNames.length - 1
                                        ? <RaisedButtonComponent
                                            placeholder={t('addPage.goHome')}
                                            onClick={() => navigate(RouteNamespaces.home)}
                                        />
                                        : <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }}>
                                            <RaisedButtonComponent
                                                placeholder={t('global.back')}
                                                backgroundColor={page >= 1 ? undefined : TemplateConfig.greyColor}
                                                textColor={page >= 1 ? undefined : TemplateConfig.blackColor}
                                                onClick={() => page >= 1 ? setPage(page - 1) : null}
                                            />
                                            <RaisedButtonComponent
                                                placeholder={page === stepNames.length - 2 ? t('addPage.upload') : t('global.next')}
                                                onClick={changePage}
                                            />
                                        </div>
                                }
                            </>
                    }
                </CardComponent>
            </div>
        </HomeWrapperComponent>
    );
}

export default ProductAddComponent;