import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import TextComponent from "../../shared/ui/TextComponent";
import HomeWrapperComponent from "../../home/ui/HomeWrapperComponent";
import PageHeaderComponent from "../../home/ui/PageHeaderComponent";
import ProductBlockWrapperComponent from "../../market/ui/ProductBlockWrapperComponent";
import { useTranslation } from "react-i18next";
import favoriteStore from "../store/FavoriteStore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ConfirmComponent from "../../shared/ui/ConfirmComponent";
import { observer } from "mobx-react-lite";
import CloseIcon from "../../../core/assets/component/modal/close.svg";

const FavoriteComponent = () => {
    const { t } = useTranslation();
    const [reload, setReload] = useState(false);
    const [confirm, setConfirm] = useState(false);
    useEffect(() => {
        if (favoriteStore.favoriteStore) setReload(!reload);
        // eslint-disable-next-line 
    }, [favoriteStore.favoriteStore]);
    function clear() {
        localStorage.removeItem("ids");
        favoriteStore.removeStore();
        setReload(!reload);
        toast(t('toast.success'), { type: "success" });
        setConfirm(false);
    }
    return (
        <HomeWrapperComponent>
            <div style={{ display: 'block' }}>
                <PageHeaderComponent title={t('pageNames.favorite')}>
                    <LinkWrapperComponent text onClick={() => setConfirm(true)}>
                        <div style={{ display: 'flex', gap: 5 }}>
                            <img src={CloseIcon} width={20} alt="market menu icon" />
                            <TextComponent>{t('favorite.clear')}</TextComponent>
                        </div>
                    </LinkWrapperComponent>
                </PageHeaderComponent>
                {
                    favoriteStore.favoriteStore.length > 0
                        ? <ProductBlockWrapperComponent items={favoriteStore.favoriteStore} t={t} />
                        : <TextComponent>{t('favorite.notFound')}</TextComponent>
                }
                {ConfirmComponent(confirm, () => setConfirm(false), t('favorite.clear'), t('favorite.confirm'), clear, t)}
            </div>
        </HomeWrapperComponent>
    );
}

export default observer(FavoriteComponent);