import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function LikeRequestService(productId: string, action: string) {
    // create params
    const params = {
        "module": "product",
        "process": "likeAttribute",
        "productId": productId,
        "action": action
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params,
            loader: false
        }
    );

    if (!result || !result.data) return null;
    return result.data;
}