import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GetConfirmProductsService from "../../admin/service/GetConfirmProductsService";
import GetMyProductsService from "../../cabinet/service/GetMyProductsService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { CustomerDataResponse } from "../model/CustomerDataResponse";
import customerDataStore from "../store/CustomerDataStore";
import LogoutService from "./LogoutService";

export default async function CustomerDataService(accessToken: string) {
    // create params
    const params = {
        "module": "account",
        "process": "data",
        "accessToken": accessToken
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params
        }
    );
    if (!result || !result.status || result.status !== 'succes' || !result.data) {
        LogoutService(false);
        return;
    }

    // result
    var customer = result.data as CustomerDataResponse;
    customerDataStore.saveCustomer(customer);

    // user
    await GetMyProductsService();

    // admin
    if ((result.data as CustomerDataResponse).customer.admin_permission === '1') {
        await GetConfirmProductsService();
    }
}