import { action, makeObservable, observable } from 'mobx';
import { ProductsResponse } from '../../market/model/ProductsResponse';

class MyProductsStore {
    productsData: ProductsResponse | null = null;
    currentPage = 1;
    status = 'active';
    loadMore = true;

    constructor() {
        makeObservable(this, {
            productsData: observable,
            currentPage: observable,
            status: observable,
            loadMore: observable,
            setLoadMore: action,
            saveProducts: action,
            deleteProduct: action,
            removeProducts: action,
            saveCurrentPage: action,
            reset: action,
            setStatus: action
        });
    }

    setLoadMore(value: boolean) { this.loadMore = value; }

    setStatus(value: string) { this.status = value; }

    saveProducts(value: ProductsResponse) {
        if (!this.productsData) this.productsData = value;
        else this.productsData.products = [...this.productsData.products, ...value.products];
    }

    deleteProduct(id: string) {
        if (!this.productsData) return;
        this.productsData.products = this.productsData.products.filter(item => item.id !== id);
    }

    archiveProduct(id: string, value: string) {
        if (!this.productsData) return;
        this.productsData.products.find(item => item.id === id)!.disabled = value;
        this.productsData.products.find(item => item.id === id)!.archive_text = null;
    }

    removeProducts() { this.productsData = null; }

    saveCurrentPage(value: number) { this.currentPage = value; }

    reset() {
        this.loadMore = true;
        this.currentPage = 1;
        this.removeProducts();
    }
}

const myProductsStore = new MyProductsStore();
export default myProductsStore;
