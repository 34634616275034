import { action, makeObservable, observable } from 'mobx';
import { Product } from '../../market/model/ProductsResponse';

class CollectionStore {
    collectionData: Product[] | null = null;
    carCount: string = '0';
    motoCount: string = '0';
    commercCount: string = '0';

    constructor() {
        makeObservable(this, {
            collectionData: observable,
            carCount: observable,
            motoCount: observable,
            commercCount: observable,
            saveCollection: action,
            removeCollection: action,
        });
    }

    saveCollection(value: Product[]) {
        this.collectionData = value;
    }

    saveCount(carCount: string, motoCount: string, commercCount: string) {
        this.carCount = carCount;
        this.motoCount = motoCount;
        this.commercCount = commercCount;
    }

    removeCollection() {
        this.collectionData = null;
    }
}

const collectionStore = new CollectionStore();
export default collectionStore;
