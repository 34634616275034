import TemplateConfig from '../../../core/config/TemplateConfig';
import TextComponent from './TextComponent';
import CloseIcon from "../../../core/assets/component/modal/close.svg";
import LinkWrapperComponent from './LinkWrapperComponent';
import Modal from 'react-modal';
import { styled } from '@mui/material';
import { ReactNode } from 'react';

const ModalComponent = ({ open, toggleModal, title = 'Информация', children, headerAction }: { open: boolean; toggleModal: any; title?: string; children: any; headerAction?: ReactNode }) => {
    // function
    function afterOpenModal() {
        document.body.style.overflow = 'hidden';
    }
    function afterCloseModal() {
        document.body.style.overflow = 'unset';
    }
    // style
    const StyledModal = styled(Modal)({
        width: TemplateConfig.isMobile ? '100%' : TemplateConfig.miniContainerMaxWidth,
        height: TemplateConfig.isMobile ? '100%' : 'none',
        backgroundColor: TemplateConfig.whiteColor,
        borderRadius: TemplateConfig.isMobile ? '0' : TemplateConfig.borderRadius,
        maxHeight: TemplateConfig.isMobile ? 'none' : '70%',
        overflow: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        ':outline': {
            border: 'none'
        },
        zIndex: 5
    });
    const ModalHeader = () => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'sticky',
                zIndex: 6,
                top: 0,
                padding: TemplateConfig.defaultPadding,
                backgroundColor: TemplateConfig.whiteColor
            }}>
                <TextComponent type='boldMedium'>{title}</TextComponent>
                {headerAction
                    ? headerAction
                    : <LinkWrapperComponent onClick={toggleModal}>
                        <img src={CloseIcon} width={24} alt="close icon" />
                    </LinkWrapperComponent>}
            </div>
        );
    }
    Modal.setAppElement('#root');

    // widget
    return (
        <StyledModal
            isOpen={open}
            onAfterOpen={afterOpenModal}
            onAfterClose={afterCloseModal}
            onRequestClose={toggleModal}
            style={{
                overlay: {
                    backgroundColor: TemplateConfig.blackColorOpacity,
                    zIndex: 5,
                    overflow: 'hidden'
                },
            }}
        >
            <ModalHeader />
            <div style={{
                paddingLeft: TemplateConfig.defaultPadding,
                paddingRight: TemplateConfig.defaultPadding,
                paddingBottom: TemplateConfig.defaultPadding
            }}>
                {children}
            </div>
        </StyledModal>
    );
}

export default ModalComponent;