import { styled } from "@mui/material";
import { ToastContainer } from "react-toastify";
import TemplateConfig from "../../../core/config/TemplateConfig";
import 'react-toastify/dist/ReactToastify.css';
import './custom.css';
import LoadingModalComponent from "./LoadingModalComponent";
import { observer } from "mobx-react-lite";
import CardComponent from "./CardComponent";

const WrapperComponent = ({ children, minimized = false }: { children: React.ReactNode; minimized?: boolean; }) => {
    const Wrapper = styled('div')({
        minHeight: 'var(--doc-height)',
        backgroundColor: TemplateConfig.greyColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: minimized ? 'center' : 'none',
        overflowX: 'hidden'
    });
    const FullContainer = styled('div')(() => ({
        width: `calc(100% -  ${TemplateConfig.defaultPadding * 2}px)`
    }));

    return (
        <div>
            <Wrapper>
                {
                    minimized ? (
                        <CardComponent
                            width={TemplateConfig.isMobile
                                ? TemplateConfig.mobileWidth
                                : TemplateConfig.miniContainerMaxWidth}
                            padding
                        >
                            {children}
                        </CardComponent >
                    ) : (
                        <FullContainer>
                            {children}
                        </FullContainer>
                    )
                }
            </Wrapper >
            <ToastContainer
                style={{ zIndex: 1000, borderRadius: TemplateConfig.borderRadius }}
                position="bottom-right"
                autoClose={1500}
                hideProgressBar={true}
                closeOnClick={false}
                pauseOnHover={false}
                theme="light"
                newestOnTop={true}
                closeButton={false}
            />
            <LoadingModalComponent />
        </div>
    );
}

export default observer(WrapperComponent);