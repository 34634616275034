import RetirementComponent from "../../shared/ui/RetirementComponent";
import TextComponent from "../../shared/ui/TextComponent";

const CreditCardHeaderComponent = ({ t }: { t: any }) => {
    return (
        <div>
            <TextComponent type="large">{t('credit.title')}</TextComponent>
            <RetirementComponent size={5} />
            <TextComponent type="extraSmall">{t('credit.description')}</TextComponent>
            <RetirementComponent />
        </div>
    );
}

export default CreditCardHeaderComponent;