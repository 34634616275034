import GridComponent from "../../shared/ui/GridComponent";
import SingleProductCardComponent from "./SingleProductCardComponent";
import { Product } from "../model/ProductsResponse"
import TemplateConfig from "../../../core/config/TemplateConfig";

const ProductBlockWrapperComponent = ({ items, t, enabledControl = false }: { items: Product[]; t: any; enabledControl?: boolean }) => {
    const MarketItem = (index: number, field: Product) => {
        return (
            <SingleProductCardComponent
                key={index}
                index={index}
                t={t}
                product={field}
                enabledControl={enabledControl}
            />
        );
    }

    return (
        <GridComponent xs={12} sm={6} md={4} rowSpacing={TemplateConfig.isMobile ? 2 : 3} columnSpacing={3}>
            {items.map((field, index) => MarketItem(index, field))}
        </GridComponent>
    );
}

export default ProductBlockWrapperComponent;