import ApplicationConfig from "../../../core/config/ApplicationConfig";
import { Product } from "../../market/model/ProductsResponse";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function GetSimilarService({ productId, brand }: { productId: string; brand: string }) {

    // create params
    const params = {
        "module": "product",
        "process": "similar",
        "productId": productId,
        "brand": brand
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params
        }
    );

    if (!result || !result.data) return null;

    // add store
    return result.data.products as Product[];
}