import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import favoriteStore from "../store/FavoriteStore";

export default async function GetFavoriteService() {
    var ids = localStorage.getItem("ids");
    if (!ids || JSON.parse(ids).length < 1) return;

    // create params
    const params = {
        "module": "product",
        "process": "favorite",
        "ids": ids
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params
        }
    );

    if (!result || !result.data) return null;

    // add store
    favoriteStore.saveStore(result.data.products);
}