import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import InputComponent from "../../shared/ui/InputComponent";
import ModalComponent from "../../shared/ui/ModalComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import UpdateDataService from "../service/UpdateDataService";

const UsernameComponent = ({ open, toggleModal }: { open: boolean; toggleModal: any }) => {
    const { t } = useTranslation();

    // username
    const nameField = useRef('');
    const onNameChange = (newValue: string) => { nameField.current = newValue };

    return (
        <ModalComponent open={open} toggleModal={toggleModal} title={t('cabinet.updateName')}>
            <InputComponent
                placeholder={t("cabinet.username")}
                handleTextFieldValueChange={onNameChange}
            />
            <RetirementComponent size={10} />
            <RaisedButtonComponent
                placeholder={t("global.next")}
                onClick={() => {
                    if (!nameField.current || nameField.current.length < 2) {
                        toast(t("toast.dataError"), { type: "success" })
                        return;
                    }
                    UpdateDataService(nameField.current, t);
                    toggleModal();
                }}
            />
        </ModalComponent>
    );
}

export default UsernameComponent;