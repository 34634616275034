import TemplateConfig from "../../../core/config/TemplateConfig";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import TextComponent from "../../shared/ui/TextComponent";

const PageHeaderComponent = ({ title, children }: { title: string; children?: any }) => {
    return (
        <div>
            {/* <RetirementComponent size={16} /> */}
            <div style={{ display: TemplateConfig.isMobile ? 'block' : 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                <TextComponent type="large">{title}</TextComponent>
                {TemplateConfig.isMobile && children ? <RetirementComponent /> : null}
                {children}
            </div>
            <RetirementComponent />
        </div>
    );
}

export default PageHeaderComponent;