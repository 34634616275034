import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function ResetAccountService() {
    // check values, get access token
    var accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;

    // create params
    const params = {
        "module": "account",
        "process": "reset",
        "accessToken": accessToken
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params
        }
    );

    if (!result || !result.data) return null;
    return true;
}