import ApplicationConfig from "../../../core/config/ApplicationConfig";
import { ProductsResponse } from "../../market/model/ProductsResponse";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import disabledProductsStore from "../store/DisabledProductsStore";

export default async function GetConfirmProductsService(page = 1) {
    // check values, get access token
    var accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;

    // create params
    const formData = new FormData();
    formData.append('module', "admin");
    formData.append('process', "products");
    formData.append('accessToken', accessToken);
    formData.append('page', page.toString());

    var result = await GlobalRequestTemplate(
        {
            type: "post",
            link: ApplicationConfig.thisProductBackendDirectory,
            formData: formData
        }
    );
    if (!result || !result.data || !result.data.products) return;

    // save store
    if ((result.data as ProductsResponse).products.length === 0) {
        disabledProductsStore.setLoadMore(false);
        return;
    }
    disabledProductsStore.saveProducts(result.data as ProductsResponse);
}