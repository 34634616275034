export default class TemplateConfig {
    // size
    static mobileMaxWidth: number = 600;
    static desktopMinWidth: number = 900;
    static miniContainerMaxWidth: number = 400;
    static mobileWidth: number = 320;
    static fullContainerMaxWidth: number = 1200;
    static isMobile = window.innerWidth <= TemplateConfig.mobileMaxWidth;
    static isDesktop = window.innerWidth > TemplateConfig.desktopMinWidth;

    // radius
    static miniBorderRadius: number = 8;
    static borderRadius: number = 16;

    // padding
    static miniPadding: number = 8;
    static defaultPadding: number = 16;

    // font size
    static extraSmallFontSize: number = 12;
    static smallFontSize: number = 14;
    static mediumFontSize: number = 16;
    static largeFontSize: number = 20;
    static extraLargeFontSize: number = 24;

    // weight
    static lightweightText: number = 200;
    static regularText: number = 300;
    static mediumText: number = 400;
    static semiboldText: number = 500;
    static heavyText: number = 700;

    // color
    static whiteColor: string = '#ffffff';
    static blackColor: string = '#000000';
    static blackColorOpacity: string = 'rgb(0, 0, 0, 0.25)';
    static greyColor: string = '#F1F4F8';
    static greyColorOpacity: string = 'rgb(241, 244, 248, 0.5)';
    static greyColorStrongOpacity: string = 'rgb(241, 244, 248, 0.8)';
    static darkGreyColor: string = 'rgba(187, 198, 211, 0.25)';
    static yellowColor: string = '#ffed00';
    static bermudaGreyColor: string = '#88949c';
    static codGreyColor: string = '#2b3134';
}