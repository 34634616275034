import RetirementComponent from "../../shared/ui/RetirementComponent";
import BottomNagivationComponent from "./BottomNagivationComponent";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";

const HomeWrapperComponent = ({ children }: { children: any }) => {
    return (
        <div>
            <HeaderComponent />
            {children}
            <RetirementComponent />
            <FooterComponent />
            <BottomNagivationComponent />
        </div>
    );
};

export default HomeWrapperComponent;