import { useState } from "react";
import LinkWrapperComponent from "./LinkWrapperComponent";
import TextComponent from "./TextComponent";

const TabBarComponent = ({ items, init, onChange }: { items: any[]; init?: string; onChange?: any }) => {
    const [active, setActive] = useState(init ?? items[0]);
    if (items.length === 0) return null;
    return (
        <div style={{
            display: 'flex',
            gap: 15
        }}>
            {
                items.map((field, index) => (
                    <div
                        key={index}
                        style={{
                            paddingBottom: 10,
                            borderBottom: active === field ? '1px solid black' : 'none',
                        }}
                    >
                        <LinkWrapperComponent onClick={() => {
                            if (onChange) onChange(field);
                            setActive(field);
                        }}>
                            <TextComponent type={active === field ? 'boldMedium' : undefined}>
                                {field}
                            </TextComponent>
                        </LinkWrapperComponent>
                    </div>
                ))
            }
        </div>
    );
}

export default TabBarComponent;