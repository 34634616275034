import CheckComponent from "../../shared/ui/CheckComponent";

const CheckItem = (field: any, t: any) => {
    return (
        <CheckComponent
            init={field.value}
            title={t(`filter.${field.text}`)}
            onChange={field.onChange}
        />
    );
}

export default CheckItem;