import { useState } from "react";
import TemplateConfig from "../../../core/config/TemplateConfig";
import VariablesConfig from "../../../core/config/VariablesConfig";
import GridComponent from "../../shared/ui/GridComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import OpenableCardComponent from "../../shared/ui/OpenableCardComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import TextComponent from "../../shared/ui/TextComponent";

const OptionItemComponent = ({ onChange, init, t }: { onChange: any; init: string[]; t: any }) => {
    const [checked, setChecked] = useState<string[]>(init);
    const elements = [
        { label: "optionOutside", items: VariablesConfig.optionOutside },
        { label: "optionSalon", items: VariablesConfig.optionSalon },
        { label: "optionConditioner", items: VariablesConfig.optionConditioner },
        { label: "optionMedia", items: VariablesConfig.optionMedia },
        { label: "optionCar", items: VariablesConfig.optionCar },
        { label: "optionAdditional", items: VariablesConfig.optionAdditional }
    ];

    return (
        <div>
            {
                elements.map((element, index) =>
                    <div key={index}>
                        <OpenableCardComponent
                            header={
                                <TextComponent weight={TemplateConfig.mediumText}>{t(`filter.${element.label}.name`)}</TextComponent>
                            }
                        >
                            <GridComponent>
                                {
                                    element.items.map(field =>
                                        <LinkWrapperComponent onClick={() => {
                                            var newValues = [...checked];
                                            if (newValues.includes(field)) {
                                                newValues = newValues.filter(item => item !== field);
                                            } else {
                                                newValues.push(field);
                                            }
                                            setChecked(newValues);
                                            onChange(newValues);
                                        }}>
                                            <div style={{ backgroundColor: checked.includes(field) ? TemplateConfig.yellowColor : TemplateConfig.greyColor, borderRadius: TemplateConfig.miniBorderRadius, padding: "4px 8px", display: 'flex', alignItems: 'center' }}>
                                                <TextComponent type={TemplateConfig.isMobile ? 'extraSmall' : 'small'} weight={TemplateConfig.lightweightText}>{t(`filter.${element.label}.${field}`)}</TextComponent>
                                            </div>
                                        </LinkWrapperComponent>
                                    )
                                }
                            </GridComponent>
                        </OpenableCardComponent>
                        {index !== elements.length - 1 && (<RetirementComponent />)}
                    </div>
                )
            }
        </div>
    );
}

export default OptionItemComponent;