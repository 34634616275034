import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TemplateConfig from "../../../core/config/TemplateConfig";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import HomeWrapperComponent from "../../home/ui/HomeWrapperComponent";
import CardComponent from "../../shared/ui/CardComponent";
import InputComponent from "../../shared/ui/InputComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import TextComponent from "../../shared/ui/TextComponent";
import FetchCreditService from "../service/FetchCreditService";
import CreditCardHeaderComponent from "./CreditCardHeaderComponent";

const CreditRequestComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isRequest, setRequest] = useState(false);

    // data
    const creditData = useRef({
        name: '',
        surname: '',
        iin: '',
        telephone: ''
    });
    const elements = [
        { label: t('credit.request.surname'), onChange: (value: string) => creditData.current.surname = value, maxLength: 100, mobileFilter: false, digitsOnly: false },
        { label: t('credit.request.name'), onChange: (value: string) => creditData.current.name = value, maxLength: 100, mobileFilter: false, digitsOnly: false },
        { label: t('credit.request.iin'), onChange: (value: string) => creditData.current.iin = value, maxLength: 12, mobileFilter: false, digitsOnly: true },
        { label: t('credit.request.telephone'), onChange: (value: string) => creditData.current.telephone = value, maxLength: 50, mobileFilter: true, digitsOnly: false }
    ];

    // function
    async function getCredit() {
        const birthYear = parseInt(creditData.current.iin.substring(0, 2));
        const birthMonth = parseInt(creditData.current.iin.substring(2, 4));
        const birthDay = parseInt(creditData.current.iin.substring(4, 6));
        if ((birthYear !== 0 && birthYear !== 1 && birthYear !== 2 && birthYear !== 3 && birthYear !== 4 && birthYear !== 5 && birthYear !== 6 && birthYear < 40) || birthMonth < 1 || birthMonth > 12 || birthDay < 1 || birthDay > 31) {
            toast(t('toast.iinError'), { type: "error" });
            return;
        }
        if (creditData.current.surname.length > 1 && creditData.current.name.length > 1 && creditData.current.iin.length === 12 && creditData.current.telephone.length > 10) {
            await FetchCreditService();
            setRequest(true);
            return;
        }
        toast(t('toast.dataError'), { type: "error" });
    }

    return (
        <HomeWrapperComponent>
            <div style={{ maxWidth: TemplateConfig.mobileMaxWidth, margin: '0 auto' }}>
                {TemplateConfig.isDesktop ? <span /> : null}
                <CardComponent padding>
                    {
                        isRequest
                            ? <>
                                <TextComponent type="large">{t('credit.request.success.title')}</TextComponent>
                                <RetirementComponent />
                                <TextComponent>{t('credit.request.success.text')}</TextComponent>
                                <RetirementComponent size={40} />
                                <RaisedButtonComponent
                                    placeholder={t('credit.request.success.button')}
                                    onClick={() => navigate(RouteNamespaces.home)}
                                />
                            </>
                            : <>
                                <CreditCardHeaderComponent t={t} />
                                {
                                    elements.map((field, index) =>
                                        <span key={index}>
                                            <InputComponent
                                                placeholder={field.label}
                                                handleTextFieldValueChange={field.onChange}
                                                maxLength={field.maxLength}
                                                mobileFilter={field.mobileFilter}
                                                digitFilter={field.digitsOnly}
                                            />
                                            {
                                                index === elements.length - 1
                                                    ? null
                                                    : <RetirementComponent size={10} />
                                            }
                                        </span>
                                    )
                                }
                                <RetirementComponent />
                                <RaisedButtonComponent
                                    placeholder={t('credit.request.button')}
                                    onClick={getCredit}
                                />
                            </>
                    }
                </CardComponent>
                {TemplateConfig.isDesktop ? <span /> : null}
            </div>
        </HomeWrapperComponent>
    )
}

export default CreditRequestComponent;