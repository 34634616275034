import { styled } from "@mui/material";
import { useState } from "react";
import Lightbox from "react-18-image-lightbox";
import BannerIcon1 from "../../../core/assets/component/swiper/1.jpg";
import BannerIcon2 from "../../../core/assets/component/swiper/2.png";
import BannerIcon3 from "../../../core/assets/component/swiper/3.jpg";
import TemplateConfig from "../../../core/config/TemplateConfig";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";

const BannerComponent = () => {
    // init
    const items = [
        BannerIcon1,
        BannerIcon2,
        BannerIcon3
    ];
    const [isOpen, setIsOpen] = useState(false);
    const [active, setActive] = useState(0);

    // widget
    const Drawer = styled('div')(() => ({
        overflowX: 'auto',
        display: 'flex',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    }));
    const BannerItem = (banner: string, index: number) => {
        return (
            <LinkWrapperComponent
                key={index}
                onClick={() => {
                    setActive(index);
                    setIsOpen(true);
                }}
            >
                <div
                    style={{
                        aspectRatio: 16 / 9,
                        marginRight: index !== items.length - 1 ? 10 : undefined,
                        height: TemplateConfig.isMobile ? 135 : undefined,
                        width: TemplateConfig.isMobile ? undefined : window.innerWidth / 3,
                        backgroundImage: `url(${banner})`,
                        backgroundSize: 'cover',
                        WebkitBackgroundSize: 'cover',
                        borderRadius: TemplateConfig.borderRadius,
                    }}
                />
            </LinkWrapperComponent>
        );
    }
    return (
        <>
            <Drawer>
                {
                    items.map((field, index) => BannerItem(field, index))
                }
            </Drawer>
            {isOpen && (
                <Lightbox
                    mainSrc={items[active]}
                    nextSrc={items[(active + 1) % items.length]}
                    prevSrc={items[(active + items.length - 1) % items.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setActive((active + items.length - 1) % items.length)}
                    onMoveNextRequest={() => setActive((active + 1) % items.length)}
                />
            )}
        </>
    );
}

export default BannerComponent;