import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TemplateConfig from "../../../core/config/TemplateConfig";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import TelephoneFormatUtil from "../../../core/utils/TelephoneFormateUtil";
import LogoutService from "../../authenticate/service/LogoutService";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import HomeWrapperComponent from "../../home/ui/HomeWrapperComponent";
import LanguageComponent from "../../home/ui/LanguageComponent";
import CardComponent from "../../shared/ui/CardComponent";
import ConfirmComponent from "../../shared/ui/ConfirmComponent";
import InputComponent from "../../shared/ui/InputComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import TextComponent from "../../shared/ui/TextComponent";
import ResetAccountService from "../service/ResetAccountService";
import UsernameComponent from "./UsernameComponent";

const CabinetComponent = () => {
    const [languageState, setLanguageState] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [username, setUsername] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    async function reset() {
        await ResetAccountService();
        await LogoutService(false);
        navigate(RouteNamespaces.home);
        navigate(RouteNamespaces.authenticate);
        toast(t("toast.success"), { type: "success" });
    }
    return (
        <HomeWrapperComponent>
            <div style={{ maxWidth: TemplateConfig.mobileMaxWidth, margin: '0 auto' }}>
                <CardComponent padding>
                    <TextComponent type="large">{t('cabinet.info')}</TextComponent>
                    <RetirementComponent />
                    <TextComponent>{`${t('cabinet.username')}: ${customerDataStore.customerData?.customer.username ?? t("cabinet.notFound")}`}</TextComponent>
                    <RetirementComponent size={10} />
                    <TextComponent>Телефон: {TelephoneFormatUtil(customerDataStore.customerData?.customer.telephone!)}</TextComponent>
                    <RetirementComponent />
                    <RaisedButtonComponent placeholder={t('cabinet.updateName')} onClick={() => setUsername(true)} />
                </CardComponent>
                <RetirementComponent />
                <CardComponent padding>
                    <TextComponent type="large">{t('pageNames.cabinet')}</TextComponent>
                    <RetirementComponent />
                    <LinkWrapperComponent onClick={() => setLanguageState(true)}>
                        <InputComponent
                            placeholder=""
                            initText={t(`global.languageFull`)}
                            disabled
                        />
                    </LinkWrapperComponent>
                    {
                        customerDataStore.customerData?.customer.admin_permission === '1'
                            ? <>
                                <RetirementComponent size={10} />
                                <RaisedButtonComponent backgroundColor={TemplateConfig.greyColor} textColor={TemplateConfig.blackColor} placeholder={t('admin.title')} onClick={() => navigate(RouteNamespaces.admin)} />
                            </>
                            : null
                    }
                    <RetirementComponent size={10} />
                    <RaisedButtonComponent backgroundColor={TemplateConfig.greyColor} textColor={TemplateConfig.blackColor} placeholder={t('cabinet.my')} onClick={() => navigate(RouteNamespaces.my)} />
                    <RetirementComponent size={10} />
                    <RaisedButtonComponent backgroundColor={TemplateConfig.greyColor} textColor={TemplateConfig.blackColor} placeholder={t('cabinet.reset')} onClick={() => setConfirm(true)} />
                    <RetirementComponent size={10} />
                    <RaisedButtonComponent placeholder={t('cabinet.logout')} onClick={() => {
                        LogoutService();
                        navigate(RouteNamespaces.home);
                        navigate(RouteNamespaces.authenticate);
                    }} />
                </CardComponent>
            </div>
            <LanguageComponent open={languageState} toggleModal={() => setLanguageState(!languageState)} />
            {ConfirmComponent(
                confirm,
                () => setConfirm(false),
                t('cabinet.reset'),
                t('cabinet.resetConfirm'),
                reset,
                t
            )}
            <UsernameComponent open={username} toggleModal={() => setUsername(false)} />
        </HomeWrapperComponent>
    );
}

export default observer(CabinetComponent);