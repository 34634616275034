import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import kk from "./i18n/kk.json";
import ru from "./i18n/ru.json";
const localization = localStorage.getItem("localization");

i18next.use(initReactI18next).init({
    resources: {
        kk: {
            translation: kk
        },
        ru: {
            translation: ru
        }
    },
    lng: localization ?? "ru",
    fallbackLng: localization ?? "ru"
});

export default i18next;