import { useEffect, useState } from "react";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import TemplateConfig from "../../../core/config/TemplateConfig";
import GridComponent from "../../shared/ui/GridComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import CloseIcon from "../../../core/assets/component/modal/close.svg";
import HomeBlackIcon from "../../../core/assets/component/btmbar/home-black.svg";
import HomeGreyIcon from "../../../core/assets/component/btmbar/home-grey.svg";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import { toast } from "react-toastify";
import TextComponent from "../../shared/ui/TextComponent";
import ParseFileUtil from "../../../core/utils/ParseFileUtil";
import Compressor from 'compressorjs';

const PhotoItemComponent = ({ t, init, onChange, editData, onEditUpload }: { t: any; init: File[] | Blob[]; onChange: any; editData?: string[]; onEditUpload: any }) => {
    // reload
    const [imageFiles, setImagesFiles] = useState(init);

    useEffect(() => {
        async function loadImages() {
            if (editData) {
                const loadedFiles = [...imageFiles];
                for (const image of editData) {
                    loadedFiles.push(await ParseFileUtil(`${ApplicationConfig.thisProductImagesDirectory}${image}`));
                }
                onChange(loadedFiles);
                setImagesFiles(loadedFiles);
                onEditUpload();
            }
        }
        loadImages();
        // eslint-disable-next-line
    }, [editData]);

    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.files) return;
        if (imageFiles.length + event.target.files.length > 10) {
            toast(t('toast.sizeError', { count: 10 }), { type: "error" });
            return;
        }

        Array.from(event.target.files).forEach((file) => {
            if ((file instanceof File || Blob) && file.type.startsWith('image/') && file.size <= ApplicationConfig.maxFileSize) {
                new Compressor(file, {
                    quality: 0.6,
                    success: (compressedResult) => {
                        setImagesFiles(prevImageFiles => {
                            const updatedImageFiles = [...prevImageFiles, compressedResult];
                            onChange(updatedImageFiles);
                            return updatedImageFiles;
                        });
                    },
                });
            }
        });
    };
    function remove(number: number) {
        const newFiles = [...imageFiles.filter((_, index) => index !== number)];
        onChange(newFiles);
        setImagesFiles(newFiles);
    }
    function setMain(number: number) {
        const field = imageFiles.find((_, index) => index === number)!;
        const newFiles = [field, ...[...imageFiles.filter((_, index) => index !== number)]];
        onChange(newFiles);
        setImagesFiles(newFiles);
    }

    return (
        <div>
            <TextComponent>{t('addPage.basePhoto')}</TextComponent>
            <RetirementComponent />
            <GridComponent xs={6} sm={4} md={3}>
                {
                    imageFiles.map((field, index) =>
                        <div
                            key={index}
                            style={{
                                aspectRatio: 1.2,
                                backgroundImage: `url(${URL.createObjectURL(field)})`,
                                backgroundSize: 'cover',
                                WebkitBackgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                borderRadius: TemplateConfig.borderRadius,
                            }}
                        >
                            <div style={{ padding: TemplateConfig.defaultPadding, display: 'flex', justifyContent: 'space-between' }}>
                                <LinkWrapperComponent onClick={() => setMain(index)}>
                                    <div style={{ width: 30, height: 30, backgroundColor: TemplateConfig.greyColorStrongOpacity, borderRadius: TemplateConfig.miniBorderRadius, display: 'flex', justifyItems: 'center', justifyContent: 'center' }}>
                                        <img src={index === 0 ? HomeBlackIcon : HomeGreyIcon} alt="home icon" width={21} />
                                    </div>
                                </LinkWrapperComponent>
                                <LinkWrapperComponent onClick={() => remove(index)}>
                                    <div style={{ width: 30, height: 30, backgroundColor: TemplateConfig.greyColorStrongOpacity, borderRadius: TemplateConfig.miniBorderRadius, display: 'flex', justifyItems: 'center', justifyContent: 'center' }}>
                                        <img src={CloseIcon} width={25} alt="close icon" style={{ textAlign: 'end' }} />
                                    </div>
                                </LinkWrapperComponent>
                            </div>
                        </div>
                    )
                }
            </GridComponent>
            <RetirementComponent />
            <input type="file" multiple accept=".jpg, .jpeg, .png, .webp" onChange={handleFileChange} />
            <RetirementComponent />
        </div>
    );
}

export default PhotoItemComponent;