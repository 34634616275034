import { useLocation, useNavigate, useParams } from "react-router-dom";
import TemplateConfig from "../../../core/config/TemplateConfig";
import HomeWrapperComponent from "../../home/ui/HomeWrapperComponent";
import CardComponent from "../../shared/ui/CardComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import TextComponent from "../../shared/ui/TextComponent";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import TelephoneFormatUtil from "../../../core/utils/TelephoneFormateUtil";
import GridComponent from "../../shared/ui/GridComponent";
import { useEffect, useState } from "react";
import GetProductDataService from "../service/GetProductDataService";
import { SingleProductResponse } from "../model/SingleProductResponse";
import { ProductAddReqest } from "../model/ProductAddRequest";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import UrlLauncherUtil from "../../../core/utils/UrlLauncherUtil";
import { useTranslation } from "react-i18next";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import ProductDescriptionComponent from "./ProductDescriptionComponent";
import { format } from 'date-fns';
import { ru, kk } from 'date-fns/locale';
import i18next from "../../../core/config/LocalizationConfig";
import ProductLikeEdit, { ThisProductLiked } from "../service/LikeAttributeService";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import DestroyProductService from "../../cabinet/service/DestroyProductService";
import ConfirmProductService from "../../admin/service/ConfirmProductService";
import ProductControlConfirmComponent from "../../market/ui/ProductControlConfirmComponent";
import { observer } from "mobx-react-lite";
import CopyTextUtil from "../../../core/utils/CopyTextUtil";
import CreditCardComponent from "../../credit/ui/CreditCardComponent";
import CalculateCreditService from "../../credit/service/CalculateCreditService";
import CalculateInitPaymentService from "../../credit/service/CalculateInitPaymentService";
import ImageGalleryComponent from "./ImageGalleryComponent";
// svg
import EyeIcon from "../../../core/assets/component/productCard/eye.svg";
import LikeIcon from "../../../core/assets/component/productCard/like.svg";
import HeartIcon from "../../../core/assets/component/productCard/heart.svg";
import HeartFillIcon from "../../../core/assets/component/productCard/heart-fill.svg";
import CheckIcon from "../../../core/assets/component/productCard/check.svg";
import EditIcon from "../../../core/assets/component/productCard/edit.svg";
import ShareIcon from "../../../core/assets/component/productCard/share.svg";
import DeleteIcon from "../../../core/assets/component/productCard/delete.svg";
import { Product } from "../../market/model/ProductsResponse";
import GetSimilarService from "../service/GetSimilarService";
import ProductBlockWrapperComponent from "../../market/ui/ProductBlockWrapperComponent";
import SetArchiveCabinetService from "../../cabinet/service/SetArchiveCabinetService";
import DisableReasonComponent from "../../admin/ui/DisableReasonComponent";

const ProductComponent = () => {
    // variables
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { t } = useTranslation();
    const [descriptionState, setDescriptionState] = useState(false);
    const [product, setProduct] = useState<null | SingleProductResponse>(null);
    const [reload, setReload] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [adminReason, setAdminReason] = useState(false);
    const [confirmName, setConfirmName] = useState('');
    const [similarProducts, setSimilarProducts] = useState<Product[]>();
    useEffect(() => {
        async function initialize() {
            const result = await GetProductDataService(id!);
            if (result) {
                window.scrollTo(0, 0);
                setProduct(result);
                const similarResult = await GetSimilarService({ productId: id!, brand: (JSON.parse(result.product.tags) as ProductAddReqest).brand });
                if (similarResult) setSimilarProducts(similarResult);
            };
        }

        if (id) initialize();
    }, [id]);

    // params
    if (!id || !product) return null;
    const decodedProduct = JSON.parse(product.product.tags) as ProductAddReqest;
    const images = decodedProduct.photo.map((url) => (`${ApplicationConfig.thisProductImagesDirectory}${url}`));
    const characteristic = [
        { title: t('filter.city.name'), value: t(`filter.city.${decodedProduct.city}`), show: decodedProduct.city.length > 0, short: true },
        { title: t('filter.body.name'), value: t(`filter.body.${decodedProduct.body}`), show: decodedProduct.body.length > 0, short: true },
        { title: t('filter.engineSize'), value: `${decodedProduct.engineSize} л`, show: decodedProduct.engineSize.length > 0, short: true },
        { title: t('filter.engine.name'), value: t(`filter.engine.${decodedProduct.engine}`), show: decodedProduct.engine.length > 0, short: true },
        { title: t('filter.mileage'), value: (`${PriceFormatUtil(Number(decodedProduct.mileage), false, '')} км`).substring(1), show: decodedProduct.mileage.length > 0, short: true },
        { title: t('filter.tranmission.name'), value: t(`filter.tranmission.${decodedProduct.tranmission}`), show: decodedProduct.tranmission.length > 0, short: true },
        { title: t('filter.drive.name'), value: t(`filter.drive.${decodedProduct.drive}`), show: decodedProduct.drive.length > 0, short: true },

        { title: t('filter.wheel.name'), value: t(`filter.wheel.${decodedProduct.wheel}`), show: decodedProduct.wheel.length > 0 },
        { title: t('filter.color.name'), value: t(`filter.color.${decodedProduct.color}`), show: decodedProduct.color.length > 0 },
        { title: t('filter.cleared'), value: decodedProduct.cleared ? t('global.yes') : t('global.no'), show: true },
        { title: t('filter.trade'), value: decodedProduct.trade ? t('global.yes') : t('global.no'), show: true },
        { title: t('filter.exchange'), value: decodedProduct.exchange ? t('global.yes') : t('global.no'), show: true },
        { title: t('filter.order'), value: decodedProduct.order ? t('global.yes') : t('global.no'), show: true }
    ];

    // options
    const control = [
        { icon: ThisProductLiked(product.product.id) ? HeartFillIcon : HeartIcon, action: like, show: product.product.disabled === '0' },
        { icon: ShareIcon, action: share, show: product.product.disabled === '0' },
        { icon: EditIcon, action: () => { setConfirmName('update'); setConfirm(true) }, show: customerDataStore.customerData && customerDataStore.customerData.customer.id === product.product.owner },
        { icon: DeleteIcon, action: () => { setConfirmName('delete'); setConfirm(true) }, show: customerDataStore.customerData && (customerDataStore.customerData.customer.id === product.product.owner || customerDataStore.customerData.customer.admin_permission === '1') },
        { icon: CheckIcon, action: () => { setConfirmName('check'); setConfirm(true) }, show: product.product.disabled === '1' && customerDataStore.customerData && customerDataStore.customerData.customer.admin_permission === '1' }
    ];
    async function share() {
        if (navigator.share) {
            await navigator.share({
                title: `${decodedProduct.brand} ${decodedProduct.model} ${decodedProduct.year}`,
                text: t('product.more'),
                url: `${ApplicationConfig.applicationLink}${location.pathname}`,
            });
            return;
        }
        CopyTextUtil({ text: `${ApplicationConfig.applicationLink}${location.pathname}`, t: t })
    }
    function like() { ProductLikeEdit(product!.product); setReload(!reload); };
    function edit() { navigate(`${RouteNamespaces.add}/${encodeURIComponent(JSON.stringify(product?.product))}`) }
    async function remove() {
        if (!customerDataStore.customerData || !product) return;

        if (customerDataStore.customerData.customer.admin_permission === '1' && product.product.disabled !== '2') setAdminReason(true);
        else if (customerDataStore.customerData.customer.id === product.product.owner && product.product.disabled === '0') await SetArchiveCabinetService(product.product.id);
        else if (customerDataStore.customerData.customer.id === product.product.owner && product.product.disabled === '1') await SetArchiveCabinetService(product.product.id);
        else if (customerDataStore.customerData.customer.id === product.product.owner && product.product.disabled === '2') await DestroyProductService(product.product.id);
        setConfirm(false);
        navigate(-1);
    }
    async function check() { setConfirm(false); await ConfirmProductService(product!.product.id); navigate(-1); }

    // widget
    const HeaderBlock = () => {
        const info = [
            {
                icon: null, value: `${TemplateConfig.isMobile ? '' : `${t('product.release')}: `}${format(Date.parse(product.product.created_at), 'dd MMMM yyyy', { locale: i18next.language === 'ru' ? ru : kk })}${decodedProduct.city.length > 0 ? `, ${t(`filter.city.${decodedProduct.city}`)}` : ''}`
            },
            { icon: EyeIcon, value: product.product.view_count },
            { icon: LikeIcon, value: product.product.like_count }
        ];

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    {
                        info.map((field, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', paddingRight: index === info.length - 1 ? 0 : 20 }}>
                                {
                                    field.icon
                                        ? <img src={field.icon} width={14} height={14} style={{ paddingRight: 5 }} alt="footer item icon" />
                                        : null
                                }
                                <TextComponent type='small'>
                                    {field.value}
                                </TextComponent>
                            </div>
                        ))
                    }
                </div>
                <RetirementComponent size={10} />
            </>
        );
    }
    const ContactBlock = () => {
        return (
            <div style={{ display: 'flex', gap: 10, padding: TemplateConfig.isMobile ? 10 : 0, backgroundColor: TemplateConfig.isMobile ? TemplateConfig.whiteColor : undefined }}>
                <RaisedButtonComponent placeholder={t('product.chat')} onClick={() => UrlLauncherUtil({ url: `https://wa.me/${product.customer.telephone}`, newTab: true })} backgroundColor={TemplateConfig.greyColor} />
                <RaisedButtonComponent placeholder={t('product.call')} onClick={() => UrlLauncherUtil({ url: TelephoneFormatUtil(product.customer.telephone), tel: true, newTab: true })} />
            </div>
        );
    }
    const MainBlock = () => {
        return (
            <CardComponent padding>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TextComponent type='large'>
                        {decodedProduct.brand} {decodedProduct.model} {decodedProduct.year}
                    </TextComponent>
                    <div style={{ display: 'flex', gap: 10 }}>
                        {
                            control.filter(item => item.show).map((field, index) => (
                                field.show
                                    ? <span key={index}>
                                        <LinkWrapperComponent onClick={field.action}>
                                            <div style={{ width: 30, height: 30, backgroundColor: TemplateConfig.greyColorStrongOpacity, borderRadius: TemplateConfig.miniBorderRadius, display: 'flex', justifyItems: 'center', justifyContent: 'center' }}>
                                                <img src={field.icon} width={20} alt="heart icon" />
                                            </div>
                                        </LinkWrapperComponent>
                                        {
                                            index === control.filter(item => item.show).length - 1
                                                ? null
                                                : <RetirementComponent size={5} />
                                        }
                                    </span>
                                    : null
                            ))
                        }
                    </div>
                </div>
                <RetirementComponent size={10} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TextComponent type='large' weight={TemplateConfig.heavyText}>{PriceFormatUtil(Number(decodedProduct.price))}</TextComponent>
                    <div style={{ backgroundColor: TemplateConfig.yellowColor, borderRadius: TemplateConfig.miniBorderRadius, padding: "4px 8px", display: 'flex', alignItems: 'center' }}>
                        <TextComponent type={TemplateConfig.isMobile ? 'extraSmall' : 'small'} weight={TemplateConfig.heavyText}>
                            {t('credit.productAmount', { amount: PriceFormatUtil(CalculateCreditService({ price: decodedProduct.price, init: CalculateInitPaymentService({ price: decodedProduct.price }).toString(), month: '84' })) })}
                        </TextComponent>
                    </div>
                </div>
                {
                    TemplateConfig.isMobile
                        ? <div style={{
                            position: 'fixed',
                            bottom: 0,
                            zIndex: 2,
                            width: '100%',
                            left: 0
                        }}>
                            <ContactBlock />
                        </div>
                        : (
                            <>
                                <RetirementComponent />
                                <ContactBlock />
                            </>
                        )
                }
                <RetirementComponent size={TemplateConfig.isMobile ? undefined : 10} />
                <RaisedButtonComponent placeholder={t('product.credit')} onClick={() => {
                    const element = document.getElementById('creditCard');
                    if (element) {
                        const elementPosition = element.getBoundingClientRect().top;
                        window.scrollBy({ top: elementPosition - 80, behavior: 'smooth' });
                    }
                }} />
            </CardComponent>
        );
    }
    const CharacteristicBlock = () => {
        return (
            <CardComponent padding>
                <TextComponent type="extraSmall" weight={TemplateConfig.semiboldText} uppercase>{t('product.characteristic')}</TextComponent>
                <RetirementComponent />
                {
                    characteristic.filter(item => item.short).map((field, index) =>
                        <div key={index}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                marginBottom: index === characteristic.filter(item => item.short).length - 1 ? 'none' : 10,
                            }}
                        >
                            <TextComponent weight={TemplateConfig.lightweightText} >{field.title}</TextComponent>
                            <TextComponent weight={TemplateConfig.mediumText}>{field.show ? field.value : t('product.empty')}</TextComponent>
                        </div>
                    )
                }
                <RetirementComponent />
                <TextComponent>
                    <LinkWrapperComponent onClick={() => setDescriptionState(true)}>
                        {t('product.characteristicFull')}
                    </LinkWrapperComponent>
                </TextComponent>
            </CardComponent>
        );
    }
    const AuthorBlock = () => {
        return (
            <CardComponent padding>
                <TextComponent type="extraSmall" weight={TemplateConfig.semiboldText} uppercase>{t('product.author')}</TextComponent>
                <RetirementComponent />
                <TextComponent type='boldMedium'>{!product.customer.username || product.customer.username.length === 0 ? t('product.projectUser') : product.customer.username}</TextComponent>
                <RetirementComponent size={5} />
                <TextComponent>{TelephoneFormatUtil(product.customer.telephone)}</TextComponent>
            </CardComponent>
        );
    }
    const DescriptionBlock = () => {
        return (
            <CardComponent padding>
                <TextComponent type="boldMedium">{t('product.description')}</TextComponent>
                <RetirementComponent size={10} />
                <TextComponent>{decodedProduct.description.length > 0 ? decodedProduct.description : t('product.noDescription')}</TextComponent>
                {
                    decodedProduct.option.length > 0
                        ? <>
                            <RetirementComponent />
                            <OptionBlock /></>
                        : null
                }
            </CardComponent>
        );
    }
    const OptionBlock = () => {
        return (
            <GridComponent>
                {
                    decodedProduct.option.map((field, index) => {
                        const translation = i18next.exists(`filter.optionOutside.${field}`)
                            ? t(`filter.optionOutside.${field}`)
                            : i18next.exists(`filter.optionSalon.${field}`)
                                ? t(`filter.optionSalon.${field}`)
                                : i18next.exists(`filter.optionConditioner.${field}`)
                                    ? t(`filter.optionConditioner.${field}`)
                                    : i18next.exists(`filter.optionMedia.${field}`)
                                        ? t(`filter.optionMedia.${field}`)
                                        : i18next.exists(`filter.optionCar.${field}`)
                                            ? t(`filter.optionCar.${field}`)
                                            : t(`filter.optionAdditional.${field}`);

                        return (
                            <div key={index} style={{ backgroundColor: TemplateConfig.yellowColor, borderRadius: TemplateConfig.miniBorderRadius, padding: "4px 8px", display: 'flex', alignItems: 'center' }}>
                                <TextComponent type={TemplateConfig.isMobile ? 'extraSmall' : 'small'} weight={TemplateConfig.lightweightText}>{translation}</TextComponent>
                            </div>
                        );
                    })
                }
            </GridComponent>
        );
    }
    const SimilarBlock = () => {
        if (!similarProducts || similarProducts.length === 0) return null;
        return (
            <div>
                <RetirementComponent />
                <TextComponent type='large'>{t('product.similar')}</TextComponent>
                <RetirementComponent />
                <ProductBlockWrapperComponent items={similarProducts} t={t} />
            </div>
        );
    }
    return (
        <HomeWrapperComponent>
            <HeaderBlock />
            <div style={{
                display: !TemplateConfig.isDesktop ? 'block' : 'grid',
                gridTemplateColumns: !TemplateConfig.isDesktop ? 'none' : '3fr auto 2fr',
            }}>
                <ImageGalleryComponent images={images} />
                <RetirementComponent />
                <div>
                    <MainBlock />
                    <RetirementComponent />
                    <CharacteristicBlock />
                    <RetirementComponent />
                    <AuthorBlock />
                </div>
            </div>
            <RetirementComponent />
            <div style={{
                display: !TemplateConfig.isDesktop ? 'block' : 'grid',
                gridTemplateColumns: !TemplateConfig.isDesktop ? 'none' : '3fr auto 2fr',
            }}>
                <div><DescriptionBlock /></div>
                <RetirementComponent />
                <div id="creditCard"><CreditCardComponent productAmount={decodedProduct.price} t={t} /></div>
            </div>
            {ProductDescriptionComponent(t, characteristic, descriptionState, () => setDescriptionState(false), `${t('product.characteristicFull')}: ${decodedProduct.brand} ${decodedProduct.model} ${decodedProduct.year} `)}
            <SimilarBlock />
            <ProductControlConfirmComponent
                open={confirm}
                toggleModal={() => setConfirm(false)}
                variant={confirmName}
                edit={edit}
                remove={remove}
                check={check}
                t={t}
            />
            <DisableReasonComponent productId={product.product.id} open={adminReason} toggleModal={() => setAdminReason(false)} t={t} />
        </HomeWrapperComponent>
    );
}

export default observer(ProductComponent);