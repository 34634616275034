import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import RouteNamespaces from './RouteNamespaces';
import { observer } from 'mobx-react-lite';
import PageNotFoundComponent from '../../module/shared/ui/PageNotFoundComponent';
import customerDataStore from '../../module/authenticate/store/CustomerDataStore';
import WrapperComponent from '../../module/shared/ui/WrapperComponent';
import AuthComponent from '../../module/authenticate/ui/AuthComponent';
import HomeComponent from '../../module/home/ui/HomeComponent';
import FavoriteComponent from '../../module/product/ui/FavoriteComponent';
import MarketComponent from '../../module/market/ui/MarketComponent';
import ProductAddComponent from '../../module/product/ui/ProductAddComponent';
import ProductComponent from '../../module/product/ui/ProductComponent';
import CabinetComponent from '../../module/cabinet/ui/CabinetComponent';
import MyProductsComponent from '../../module/cabinet/ui/MyProductsComponent';
import DisabledProductsComponent from '../../module/admin/ui/DisabledProductsComponent';
import CreditRequestComponent from '../../module/credit/ui/CreditRequestComponent';
import HelpComponent from '../../module/help/ui/HelpComponent';

function RouteComponent() {
    const PublicRoute = ({ children }: { children: any }) => {
        return !customerDataStore.customerData ? children : <Navigate to={RouteNamespaces.cabinet} />;
    };

    const PrivateRoute = ({ children }: { children: any }) => {
        return customerDataStore.customerData ? children : <Navigate to={RouteNamespaces.authenticate} />;
    };

    const AdminRoute = ({ children }: { children: any }) => {
        return customerDataStore.customerData && customerDataStore.customerData.customer.admin_permission === '1' ? children : <Navigate to={RouteNamespaces.home} />;
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={RouteNamespaces.authenticate}
                    element={
                        <PublicRoute>
                            <WrapperComponent minimized>
                                <AuthComponent />
                            </WrapperComponent>
                        </PublicRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.home}
                    element={
                        <WrapperComponent>
                            <HomeComponent />
                        </WrapperComponent>
                    }
                />
                <Route
                    path={RouteNamespaces.favorite}
                    element={
                        <WrapperComponent>
                            <FavoriteComponent />
                        </WrapperComponent>
                    }
                />
                <Route
                    path={RouteNamespaces.help}
                    element={
                        <WrapperComponent>
                            <HelpComponent />
                        </WrapperComponent>
                    }
                />
                <Route
                    path={RouteNamespaces.creditRequest}
                    element={
                        <WrapperComponent>
                            <CreditRequestComponent />
                        </WrapperComponent>
                    }
                />
                <Route
                    path={RouteNamespaces.market}
                    element={
                        <WrapperComponent>
                            <MarketComponent />
                        </WrapperComponent>
                    }
                />
                <Route
                    path={RouteNamespaces.add}
                    element={
                        <PrivateRoute>
                            <WrapperComponent>
                                <ProductAddComponent />
                            </WrapperComponent>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.addWithParam}
                    element={
                        <PrivateRoute>
                            <WrapperComponent>
                                <ProductAddComponent />
                            </WrapperComponent>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.item}
                    element={
                        <WrapperComponent>
                            <ProductComponent />
                        </WrapperComponent>
                    }
                />
                <Route
                    path={RouteNamespaces.cabinet}
                    element={
                        <PrivateRoute>
                            <WrapperComponent>
                                <CabinetComponent />
                            </WrapperComponent>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.my}
                    element={
                        <PrivateRoute>
                            <WrapperComponent>
                                <MyProductsComponent />
                            </WrapperComponent>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.admin}
                    element={
                        <AdminRoute>
                            <WrapperComponent>
                                <DisabledProductsComponent />
                            </WrapperComponent>
                        </AdminRoute>
                    }
                />
                <Route path={RouteNamespaces.error} element={<PageNotFoundComponent />} />
            </Routes>
        </BrowserRouter>
    );
}

export default observer(RouteComponent);