import { CardMedia } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import CardComponent from "../../shared/ui/CardComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import TextComponent from "../../shared/ui/TextComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import { useNavigate } from "react-router-dom";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import { Product } from "../model/ProductsResponse";
import { format } from 'date-fns';
import { ru, kk } from 'date-fns/locale';
import i18next from "../../../core/config/LocalizationConfig";
import { ProductAddReqest } from "../../product/model/ProductAddRequest";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import ProductLikeEdit, { ThisProductLiked } from "../../product/service/LikeAttributeService";
import { useState } from "react";
import { toast } from "react-toastify";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import DestroyProductService from "../../cabinet/service/DestroyProductService";
import ProductControlConfirmComponent from "./ProductControlConfirmComponent";
import ConfirmProductService from "../../admin/service/ConfirmProductService";
import CalculateCreditService from "../../credit/service/CalculateCreditService";
import CalculateInitPaymentService from "../../credit/service/CalculateInitPaymentService";
// images
import EyeIcon from "../../../core/assets/component/productCard/eye.svg";
import LikeIcon from "../../../core/assets/component/productCard/like.svg";
import HeartIcon from "../../../core/assets/component/productCard/heart.svg";
import HeartFillIcon from "../../../core/assets/component/productCard/heart-fill.svg";
import CheckIcon from "../../../core/assets/component/productCard/check.svg";
import EditIcon from "../../../core/assets/component/productCard/edit.svg";
import DeleteIcon from "../../../core/assets/component/productCard/delete.svg";
import ProjectCarIcon from "../../../core/assets/component/productCard/projectCar.svg";
import OwnerCarIcon from "../../../core/assets/component/productCard/ownerCar.svg";
import NewCarIcon from "../../../core/assets/component/productCard/newCar.svg";
import DisableReasonComponent from "../../admin/ui/DisableReasonComponent";
import SetArchiveCabinetService from "../../cabinet/service/SetArchiveCabinetService";
import ReasonComponent from "../../cabinet/ui/ReasonComponent";

const SingleProductCardComponent = ({ index, t, product, enabledControl }: { index?: number; t: any; product: Product; enabledControl: boolean }) => {
    // variable
    const navigate = useNavigate();
    const decodedProduct = JSON.parse(product.tags) as ProductAddReqest;
    const [reload, setReload] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [confirmName, setConfirmName] = useState('');
    const [reasonState, setReasonState] = useState(false);
    const [adminReason, setAdminReason] = useState(false);
    const tiles = [
        { name: t('filter.newcar'), icon: NewCarIcon, color: TemplateConfig.yellowColor, show: decodedProduct.newcar },
        { name: t('filter.owner'), icon: OwnerCarIcon, color: TemplateConfig.bermudaGreyColor, show: decodedProduct.owner },
        { name: t('filter.projectCar'), icon: ProjectCarIcon, color: TemplateConfig.codGreyColor, show: decodedProduct.projectCar }
    ];
    const info = [
        { icon: null, value: format(Date.parse(product.created_at), 'dd MMMM', { locale: i18next.language === 'ru' ? ru : kk }) },
        { icon: EyeIcon, value: product.view_count },
        { icon: LikeIcon, value: product.like_count }
    ];
    const control = [
        { icon: ThisProductLiked(product.id) ? HeartFillIcon : HeartIcon, action: like, show: product.disabled === '0' },
        { icon: EditIcon, action: (event: any) => { setConfirmName('update'); setConfirm(true); event.stopPropagation(); }, show: enabledControl && customerDataStore.customerData && customerDataStore.customerData.customer.id === product.owner },
        { icon: DeleteIcon, action: (event: any) => { setConfirmName('delete'); setConfirm(true); event.stopPropagation(); }, show: enabledControl && customerDataStore.customerData && (customerDataStore.customerData.customer.id === product.owner || customerDataStore.customerData.customer.admin_permission === '1') },
        { icon: CheckIcon, action: (event: any) => { setConfirmName('check'); setConfirm(true); event.stopPropagation(); }, show: enabledControl && product.disabled === '1' && customerDataStore.customerData && customerDataStore.customerData.customer.admin_permission === '1' }
    ];

    // function
    function like(event: any) { ProductLikeEdit(product); setReload(!reload); event.stopPropagation(); };
    function edit() { navigate(`${RouteNamespaces.add}/${encodeURIComponent(JSON.stringify(product))}`) }
    async function remove() {
        if (!customerDataStore.customerData) return;

        if (customerDataStore.customerData.customer.admin_permission === '1' && product.disabled !== '2') setAdminReason(true);
        else if (customerDataStore.customerData.customer.id === product.owner && product.disabled === '0') await SetArchiveCabinetService(product.id);
        else if (customerDataStore.customerData.customer.id === product.owner && product.disabled === '1') await SetArchiveCabinetService(product.id);
        else if (customerDataStore.customerData.customer.id === product.owner && product.disabled === '2') await DestroyProductService(product.id);
        setConfirm(false);
    }
    async function check() { setConfirm(false); await ConfirmProductService(product.id); }
    function openReason(event: any) { setReasonState(true); event.stopPropagation(); };

    // widget
    const TileItem = (item: any) => {
        return (
            <div
                style={{
                    borderRadius: TemplateConfig.miniBorderRadius,
                    backgroundColor: item.color,
                    display: 'flex',
                    padding: '4px 8px',
                    alignItems: 'center'
                }}
            >
                <img src={item.icon} width={14} style={{ paddingRight: 5 }} alt="header tile icon" />
                <TextComponent type="extraSmall" color={item.name === t('filter.newcar') ? undefined : TemplateConfig.whiteColor} weight={TemplateConfig.mediumText} >
                    {item.name}
                </TextComponent>
            </div>
        );
    }
    const ItemHeader = () => {
        return (
            <CardMedia
                sx={{ height: 220 }}
                image={`${ApplicationConfig.thisProductImagesDirectory}${decodedProduct.photo[0]}`}
                style={{
                    borderTopLeftRadius: TemplateConfig.borderRadius,
                    borderTopRightRadius: TemplateConfig.borderRadius
                }}
            >
                <div style={{
                    display: 'flex',
                    padding: TemplateConfig.defaultPadding,
                    justifyContent: 'space-between'
                }}>
                    <div>
                        {
                            tiles.map((field, index) => (
                                field.show
                                    ? <span key={index}>
                                        {TileItem(field)}
                                        {
                                            index === tiles.length - 1
                                                ? null
                                                : <RetirementComponent size={5} />
                                        }
                                    </span>
                                    : null
                            ))
                        }
                    </div>
                    <div>
                        {
                            control.filter(item => item.show).map((field, index) => (
                                field.show
                                    ? <span key={index} >
                                        <LinkWrapperComponent onClick={field.action}>
                                            <div style={{ width: 30, height: 30, backgroundColor: TemplateConfig.greyColorStrongOpacity, borderRadius: TemplateConfig.miniBorderRadius, display: 'flex', justifyItems: 'center', justifyContent: 'center' }}>
                                                <img src={field.icon} width={20} alt="control icon" />
                                            </div>
                                        </LinkWrapperComponent>
                                        {
                                            index === control.filter(item => item.show).length - 1
                                                ? null
                                                : <RetirementComponent size={5} />
                                        }
                                    </span>
                                    : null
                            ))
                        }
                    </div>
                </div>
            </CardMedia>
        );
    }
    const ItemBody = () => {
        return (
            <div style={{ padding: `0px ${TemplateConfig.defaultPadding}px` }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <TextComponent type="boldMedium">
                            {PriceFormatUtil(Number(decodedProduct.price))}
                        </TextComponent>
                        <RetirementComponent size={10} />
                        <TextComponent>
                            {decodedProduct.brand} {decodedProduct.model} {decodedProduct.year}
                        </TextComponent>
                    </div>
                    <div> <div style={{ backgroundColor: TemplateConfig.yellowColor, borderRadius: TemplateConfig.miniBorderRadius, padding: "4px 8px", display: 'flex', alignItems: 'center' }}>
                        <TextComponent type={TemplateConfig.isMobile ? 'extraSmall' : 'small'} weight={TemplateConfig.heavyText}>
                            {t('credit.productAmount', { amount: PriceFormatUtil(CalculateCreditService({ price: decodedProduct.price, init: CalculateInitPaymentService({ price: decodedProduct.price }).toString(), month: '84' })) })}
                        </TextComponent>
                    </div></div>
                </div>
                <RetirementComponent size={15} />
                <ItemFooter />
            </div>
        );
    }
    const ItemFooter = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                    {
                        info.map((field, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', paddingRight: index === info.length - 1 ? 0 : 20 }}>
                                {
                                    field.icon
                                        ? <img src={field.icon} width={14} height={14} style={{ paddingRight: 5 }} alt="footer item icon" />
                                        : null
                                }
                                <TextComponent type='small'>
                                    {field.value}
                                </TextComponent>
                            </div>
                        ))
                    }
                </div>
                {
                    product.disabled === '2' && product.archive_text
                    && (
                        <LinkWrapperComponent onClick={openReason}>
                            <TextComponent type='small' color='#FF0000'>
                                {t('cabinet.reject')}
                            </TextComponent>
                        </LinkWrapperComponent>
                    )
                }
            </div>
        );
    }
    return (
        <>
            <CardComponent key={index}>
                <LinkWrapperComponent onClick={() => {
                    if (product.disabled === '1' && (!customerDataStore.customerData || customerDataStore.customerData.customer.admin_permission === '0')) toast(t('toast.moderError'), { type: "error" });
                    else navigate(`${RouteNamespaces.itemWithoutParam}/${product.id}`);
                }}>
                    <ItemHeader />
                    <RetirementComponent />
                    <ItemBody />
                    <RetirementComponent size={10} />
                </LinkWrapperComponent>
                <ProductControlConfirmComponent
                    open={confirm}
                    toggleModal={() => setConfirm(false)}
                    variant={confirmName}
                    edit={edit}
                    remove={remove}
                    check={check}
                    t={t}
                />
            </CardComponent>
            <DisableReasonComponent productId={product.id} open={adminReason} toggleModal={() => setAdminReason(false)} t={t} />
            <ReasonComponent open={reasonState} toggleModal={() => setReasonState(false)} reason={product.archive_text ?? ''} t={t} />
        </>
    );
}

export default SingleProductCardComponent;