import { action, makeObservable, observable } from 'mobx';
import VariablesConfig from '../../../core/config/VariablesConfig';
import { FilterResponse } from '../model/FilterResponse';
import GetProductsService from '../service/GetProductsService';
import productResponseStore from './ProductsStore';

class FilterStore {
    filterStore: FilterResponse = VariablesConfig.defaultFilter;

    constructor() {
        makeObservable(this, {
            filterStore: observable,
            saveFilter: action,
            removeFilter: action,
        });
    }

    saveFilter(value: FilterResponse) {
        this.filterStore = value;
        productResponseStore.reset();
        GetProductsService(value);
    }

    removeFilter(category: string) {
        this.filterStore = VariablesConfig.defaultFilter;
        this.filterStore.category = category;
        productResponseStore.reset();
        GetProductsService(this.filterStore);
    }
}

const filterResponseStore = new FilterStore();
export default filterResponseStore;
