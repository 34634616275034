import TemplateConfig from "../../../core/config/TemplateConfig";
import HeaderLogotype from "../../../core/assets/shared/logo-header.svg";
import { useTranslation } from "react-i18next";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import ArrowDownIcon from "../../../core/assets/shared/arrow-down.svg";
import TextComponent from "../../shared/ui/TextComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import { useNavigate } from "react-router-dom";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import LanguageComponent from "./LanguageComponent";
import { useState } from "react";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import BackIcon from "../../../core/assets/component/gallery/back.svg";

const HeaderComponent = () => {
    const [languageState, setLanguageState] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    // mobile
    const Mobile = () => {
        return (
            <>
                <div style={{
                    height: 15,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    backgroundColor: TemplateConfig.yellowColor,
                    width: '100%',
                    zIndex: 3
                }} />
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    height: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: TemplateConfig.yellowColor,
                    paddingLeft: 20,
                    paddingRight: 40,
                    zIndex: 3,
                    borderBottomRightRadius: 45,
                    alignItems: 'center'
                }}>
                    {
                        RouteNamespaces.itemWithoutParam === window.location.pathname.substring(0, 5)
                            ? <div style={{ width: 24, paddingRight: TemplateConfig.defaultPadding }}>
                                <LinkWrapperComponent onClick={() => navigate(-1)}>
                                    <img src={BackIcon} alt="back icon" width={24} />
                                </LinkWrapperComponent>
                            </div>
                            : <div />
                    }
                    <img src={HeaderLogotype} alt="logotype" width={150} />
                    <div />
                </div>
            </>
        )
    }
    // desktop
    const Desktop = () => {
        return (
            <div>
                <div style={{ backgroundColor: TemplateConfig.whiteColor, paddingLeft: TemplateConfig.defaultPadding, paddingRight: TemplateConfig.defaultPadding, borderBottomLeftRadius: TemplateConfig.borderRadius, borderBottomRightRadius: TemplateConfig.borderRadius }}>
                    <RetirementComponent />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <LinkWrapperComponent onClick={() => navigate(RouteNamespaces.home)}>
                            <img src={HeaderLogotype} alt="logotype" width={150} />
                        </LinkWrapperComponent>
                        <div style={{ display: 'flex' }}>
                            {
                                elements.map((field, index) =>
                                    <div key={index} style={{ paddingLeft: 25 }}>

                                        <LinkWrapperComponent onClick={field.onClick
                                            ? field.onClick
                                            : () => navigate(field.link)
                                        }>
                                            <TextComponent type="boldMedium">
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    {field.text}
                                                    {
                                                        field.icon
                                                            ? <img src={field.icon} alt="field icon" />
                                                            : null
                                                    }
                                                </div>
                                            </TextComponent>
                                        </LinkWrapperComponent>

                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <RetirementComponent />
                    <LanguageComponent open={languageState} toggleModal={() => setLanguageState(!languageState)} />
                </div>
                <RetirementComponent />
            </div>
        )
    }
    // items
    const elements = [
        {
            text: t('global.language'), onClick: () => setLanguageState(true), icon: ArrowDownIcon
        },
        { text: t('pageNames.favorite'), link: RouteNamespaces.favorite },
        { text: t('pageNames.addAnother'), link: !customerDataStore.customerData ? RouteNamespaces.authenticate : RouteNamespaces.add },
        { text: t('pageNames.help'), link: RouteNamespaces.help },
        { text: customerDataStore.customerData ? t('pageNames.cabinet') : t('pageNames.authenticate'), link: customerDataStore.customerData ? RouteNamespaces.cabinet : RouteNamespaces.authenticate }
    ];
    return (
        !TemplateConfig.isMobile
            ? <Desktop />
            : <div style={{ height: 75 }}>
                <Mobile />
            </div>
    );
}

export default HeaderComponent;