import ApplicationConfig from "../../../core/config/ApplicationConfig";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { AuthorizeResponse } from "../model/AuthorizeResponse";
import GlobalAuthVerifyService from "./GlobalAuthVerifyService";

export default async function AuthorizationService({ telephone, code, navigate }: { telephone: string; code: string; navigate: any }) {
    const params = {
        "module": "account",
        "process": "auth",
        "telephone": telephone,
        "code": code
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params
        }
    );
    if (!result || !result.data) return;
    if (code) {
        var accessToken = (result.data as AuthorizeResponse).access_token;
        localStorage.setItem('accessToken', accessToken);
        await GlobalAuthVerifyService();
        navigate(RouteNamespaces.home);
        return;
    }

    return true;
}