import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TemplateConfig from "../../../core/config/TemplateConfig";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import CardComponent from "../../shared/ui/CardComponent";
import GridComponent from "../../shared/ui/GridComponent";
import InputComponent from "../../shared/ui/InputComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import TextComponent from "../../shared/ui/TextComponent";
import CalculateCreditService from "../service/CalculateCreditService";
import CalculateInitPaymentService from "../service/CalculateInitPaymentService";
import CreditCardHeaderComponent from "./CreditCardHeaderComponent";

const CreditCardComponent = ({ productAmount, t }: { productAmount?: string; t: any }) => {
    return (
        <CardComponent padding>
            <CreditCardComponentImpl productAmount={productAmount} t={t} />
        </CardComponent>
    );
}
export default CreditCardComponent;

const CreditCardComponentImpl = ({ productAmount, t }: { productAmount?: string; t: any }) => {
    const navigate = useNavigate();

    // product amount
    const [productAmountRef, setProductAmountRef] = useState(productAmount ? PriceFormatUtil(Number(productAmount)) : '');
    const onAmountChange = (newValue: string) => { setProductAmountRef(newValue) };

    // init payment
    const [initPaymentRef, setInitPaymentRef] = useState(productAmount ? PriceFormatUtil(CalculateInitPaymentService({ price: productAmount })) : '');
    const onPaymentChange = (newValue: string) => { setInitPaymentRef(newValue) };

    // term
    const terms = [12, 24, 36, 48, 60, 72, 84];
    const [activeTerm, setActiveTerm] = useState(terms[terms.length - 1]);

    const FooterBlock = () => {
        return (
            <div>
                <TextComponent type="extraSmall">{t('credit.resultTitle')}</TextComponent>
                <RetirementComponent size={5} />
                <TextComponent type="extraLarge">
                    {t('credit.result', {
                        amount: PriceFormatUtil(CalculateCreditService({
                            price: productAmount ?? productAmountRef.replace(/[^0-9]/g, ""),
                            init: initPaymentRef.replace(/[^0-9]/g, ""),
                            month: activeTerm.toString()
                        }))
                    })}
                </TextComponent>
                <RetirementComponent size={10} />
                <RaisedButtonComponent
                    placeholder={t('credit.button')}
                    onClick={() => navigate(RouteNamespaces.creditRequest)}
                />
            </div>
        );
    }
    return (
        <div>
            <CreditCardHeaderComponent t={t} />
            <div>
                <TextComponent type="extraSmall">{t('credit.productAmountTitle')}</TextComponent>
                <RetirementComponent size={5} />
                <InputComponent
                    initText={productAmountRef}
                    placeholder={t('credit.productAmountTitle')}
                    handleTextFieldValueChange={onAmountChange}
                    // disabled={productAmount ? true : false}
                    priceFilter
                />
                <RetirementComponent size={10} />
                <TextComponent type="extraSmall">{t('credit.initPaymentTitle')} ({PriceFormatUtil(CalculateInitPaymentService({ price: productAmountRef.replace(/[^0-9]/g, "") }))} - {productAmount ? productAmountRef : PriceFormatUtil(Number(productAmountRef.replace(/[^0-9]/g, "")))})</TextComponent>
                <RetirementComponent size={5} />
                <InputComponent
                    initText={initPaymentRef}
                    placeholder={t('credit.initPaymentTitle')}
                    handleTextFieldValueChange={onPaymentChange}
                    priceFilter
                />
                <RetirementComponent />
                <TextComponent type="extraSmall">{t('credit.creditMonth')}</TextComponent>
                <RetirementComponent size={5} />
                <GridComponent>
                    {
                        terms.map((field, index) =>
                            <RaisedButtonComponent
                                key={index}
                                placeholder={t('credit.monthItem', { month: field.toString() })}
                                onClick={() => setActiveTerm(field)}
                                backgroundColor={activeTerm === field ? undefined : TemplateConfig.greyColor}
                                padding='5px 20px'
                                textType='small'
                            />
                        )
                    }
                </GridComponent>
                <RetirementComponent />
            </div>
            <FooterBlock />
        </div>
    );
}