import { useRef } from "react";
import { toast } from "react-toastify";
import InputComponent from "../../shared/ui/InputComponent";
import ModalComponent from "../../shared/ui/ModalComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import SetArchiveText from "../service/SetArchiveText";

const DisableReasonComponent = ({ open, toggleModal, productId, t }: { open: boolean; toggleModal: any; productId: string; t: any }) => {
    const textField = useRef('');
    const onTextChange = (newValue: string) => { textField.current = newValue };
    async function exec() {
        if (!textField.current || textField.current.length < 3) {
            toast(t('toast.dataError'), { type: "error" });
            return;
        }

        await SetArchiveText(productId, textField.current);
        toggleModal();
    }
    return (
        <ModalComponent open={open} toggleModal={toggleModal} title={t('admin.reason')}>
            <InputComponent
                placeholder=''
                handleTextFieldValueChange={onTextChange}
            />
            <RetirementComponent size={10} />
            <RaisedButtonComponent
                placeholder={t("global.next")}
                onClick={exec}
            />
        </ModalComponent>
    );
}

export default DisableReasonComponent;