export default class ApplicationConfig {
    // namespace
    static applicationName: string = 'AvtoMarket Group';

    // link
    static applicationLink: string = 'https://avtomarket.site';
    static thisProductImagesDirectory: string = `${ApplicationConfig.applicationLink}/request/api/v1/`;
    static thisProductBackendDirectory: string = `${ApplicationConfig.applicationLink}/request/api/v1/main.php`;

    // file
    static maxFileSize = 27 * 1024 * 1024;

    // market
    static countProductsInBlock = 24;

    // credit
    static creditProcent = 0.2;

    // map
    static yandexMap = "https://yandex.com/map-widget/v1/?um=constructor%3Ac0f618c3105f80fed14c3316269b374169f6cdf743353f9b98ea1c11ce9ad2ef&amp;source=constructor";
}