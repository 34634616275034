import { action, makeObservable, observable } from 'mobx';
import { ProductsResponse } from '../model/ProductsResponse';

class ProductsStore {
    productsData: ProductsResponse | null = null;
    currentPage = 1;
    loadMore = false;

    constructor() {
        makeObservable(this, {
            productsData: observable,
            currentPage: observable,
            loadMore: observable,
            setLoadMore: action,
            saveProducts: action,
            removeProducts: action,
            saveCurrentPage: action,
            reset: action
        });
    }

    setLoadMore(value: boolean) { this.loadMore = value; }

    saveProducts(value: ProductsResponse) {
        if (!this.productsData) this.productsData = value;
        else this.productsData.products = [...this.productsData.products, ...value.products];
    }

    removeProducts() { this.productsData = null; }

    saveCurrentPage(value: number) { this.currentPage = value; }

    reset() {
        this.loadMore = true;
        this.currentPage = 1;
        this.removeProducts();
    }
}

const productResponseStore = new ProductsStore();
export default productResponseStore;
