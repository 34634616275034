import { useState } from "react";
import Lightbox from "react-18-image-lightbox";
import 'react-18-image-lightbox/style.css';
import TemplateConfig from "../../../core/config/TemplateConfig";
import GridComponent from "../../shared/ui/GridComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import ExpandIcon from "../../../core/assets/component/gallery/expand.svg";

const ImageGalleryComponent = ({ images }: { images: string[] }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [active, setActive] = useState(0);
    if (images.length === 0) return null;
    return (
        <div>
            <LinkWrapperComponent onClick={() => setIsOpen(true)}>
                <div
                    style={{
                        aspectRatio: 16 / 9,
                        backgroundImage: `url(${images[active]})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: TemplateConfig.borderRadius,
                        padding: TemplateConfig.defaultPadding,
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'end'
                    }}
                >
                    <div style={{ width: 30, height: 30, backgroundColor: TemplateConfig.greyColorStrongOpacity, borderRadius: TemplateConfig.miniBorderRadius, display: 'flex', justifyItems: 'center', justifyContent: 'center' }}>
                        <img src={ExpandIcon} width={16} alt="expand icon" />
                    </div>
                </div>
            </LinkWrapperComponent>
            <RetirementComponent />
            <GridComponent xs={3} sm={2} rowSpacing={1} columnSpacing={1} >
                {
                    images.map((field, index) =>
                        <LinkWrapperComponent key={index} onClick={() => setActive(index)}>
                            <div
                                style={{
                                    aspectRatio: 4 / 3,
                                    backgroundImage: `url(${field})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    borderRadius: TemplateConfig.isMobile ? TemplateConfig.miniBorderRadius : TemplateConfig.borderRadius
                                }}
                            />
                        </LinkWrapperComponent>
                    )
                }
            </GridComponent>
            {isOpen && (
                <Lightbox
                    mainSrc={images[active]}
                    nextSrc={images[(active + 1) % images.length]}
                    prevSrc={images[(active + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setActive((active + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setActive((active + 1) % images.length)}
                />
            )}
        </div>
    );
}

export default ImageGalleryComponent;