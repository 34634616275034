import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import i18next from "../../../core/config/LocalizationConfig";
import { ListModel } from "../../shared/model/ListModel";
import ListComponent from "../../shared/ui/ListComponent";
import ModalComponent from "../../shared/ui/ModalComponent";

const LanguageComponent = ({ open, toggleModal }: { open: boolean; toggleModal: any }) => {
    const { t } = useTranslation();
    function changeLanguage(newLanguage: string) {
        i18next.changeLanguage(newLanguage);
        localStorage.setItem('localization', newLanguage);
        toggleModal();
        toast(t('toast.success'), { type: "success" });
    }
    const items: ListModel[] = [
        { label: "Қазақша", onClick: () => changeLanguage('kk') },
        { label: "Русский", onClick: () => changeLanguage('ru') }
    ];

    return (
        <ModalComponent open={open} toggleModal={toggleModal} title={t('component.language.title')} >
            <ListComponent items={items} />
        </ModalComponent>
    );
}

export default LanguageComponent;