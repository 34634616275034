import { ReactNode, useState } from "react";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import RetirementComponent from "./RetirementComponent";

const OpenableCardComponent = ({ header, children }: { header: ReactNode; children: ReactNode }) => {
    //init
    const [isOpen, setOpen] = useState(false);

    return (
        <div>
            <LinkWrapperComponent onClick={() => setOpen(!isOpen)}>
                {header}
            </LinkWrapperComponent>
            {
                isOpen && (<>
                    <RetirementComponent />
                    {children}
                </>)
            }
        </div>
    )
}

export default OpenableCardComponent;