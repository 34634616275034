import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function UserHelperService(t: any, name: string, telephone: string) {
    // create params
    const params = {
        "module": "userHelper",
        "name": name,
        "telephone": telephone
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params
        }
    );

    if (!result || !result.data) return null;
    toast(t("help.success"), { type: "success" });
}