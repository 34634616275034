import { styled } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";

const LinkWrapperComponent = ({ children, onClick, text = true }: { children: any; onClick: any; text?: boolean }) => {
  const StyledSpan = styled('span')({
    cursor: 'pointer',
    color: text ? TemplateConfig.codGreyColor : 'none',
    '&:hover': {
      color: text ? TemplateConfig.codGreyColor : 'none',
    },
  });

  return (
    <StyledSpan onClick={onClick}>
      {children}
    </StyledSpan>
  );
}

export default LinkWrapperComponent;