import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import TemplateConfig from "../../../core/config/TemplateConfig";
import HomeWrapperComponent from "../../home/ui/HomeWrapperComponent";
import CardComponent from "../../shared/ui/CardComponent";
import InputComponent from "../../shared/ui/InputComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import TextComponent from "../../shared/ui/TextComponent";
import UserHelperService from "../service/UserHelperService";

const HelpComponent = () => {
    const [t] = useTranslation();

    // name
    const nameField = useRef('');
    const onNameChange = (newValue: string) => { nameField.current = newValue };

    // telephone
    const telephoneField = useRef('');
    const onTelephoneChange = (newValue: string) => { telephoneField.current = newValue };

    // send
    async function send() {
        if (!nameField.current || nameField.current.length < 2) toast(t("help.nameError"), { type: "error" });
        else if (!telephoneField.current || telephoneField.current.length !== 17) toast(t("help.telephoneError"), { type: "error" });
        else await UserHelperService(t, nameField.current, telephoneField.current);
    }

    return (
        <HomeWrapperComponent>
            <div style={{ maxWidth: TemplateConfig.mobileMaxWidth, margin: '0 auto' }}>
                {TemplateConfig.isDesktop ? <span /> : null}
                <div>
                    <CardComponent padding>
                        <TextComponent type="large">{t("help.title")}</TextComponent>
                        <RetirementComponent size={5} />
                        <TextComponent type="extraSmall">{t("help.description")}</TextComponent>
                        <RetirementComponent />
                        <InputComponent
                            placeholder={t("help.name")}
                            handleTextFieldValueChange={onNameChange}
                        />
                        <RetirementComponent size={10} />
                        <InputComponent
                            placeholder={t("help.telephone")}
                            handleTextFieldValueChange={onTelephoneChange}
                            mobileFilter
                        />
                        <RetirementComponent size={10} />
                        <RaisedButtonComponent
                            placeholder={t("help.send")}
                            onClick={() => send()}
                        />
                    </CardComponent>
                    <RetirementComponent />
                    <iframe
                        title={ApplicationConfig.applicationName}
                        src={ApplicationConfig.yandexMap}
                        width='100%'
                        height={250}
                        style={{
                            border: 'none',
                            borderRadius: TemplateConfig.borderRadius
                        }}
                    />
                </div>
                {TemplateConfig.isDesktop ? <span /> : null}
            </div>
        </HomeWrapperComponent>
    );
}

export default HelpComponent;