import { action, makeObservable, observable } from 'mobx';
import { ProductsResponse } from '../../market/model/ProductsResponse';

class DisabledProductsStore {
    productsData: ProductsResponse | null = null;
    loadMore = true;
    currentPage = 1;

    constructor() {
        makeObservable(this, {
            productsData: observable,
            currentPage: observable,
            loadMore: observable,
            setLoadMore: action,
            saveProducts: action,
            deleteProduct: action,
            removeProducts: action,
            saveCurrentPage: action,
            reset: action
        });
    }

    setLoadMore(value: boolean) { this.loadMore = value; }

    saveProducts(value: ProductsResponse) {
        if (!this.productsData) this.productsData = value;
        else this.productsData.products = [...this.productsData.products, ...value.products];
    }

    deleteProduct(id: string) {
        if (!this.productsData) return;
        this.productsData.products = this.productsData.products.filter(item => item.id !== id);
    }

    removeProducts() { this.productsData = null; }

    saveCurrentPage(value: number) { this.currentPage = value; }

    reset() {
        this.loadMore = true;
        this.currentPage = 1;
        this.removeProducts();
    }
}

const disabledProductsStore = new DisabledProductsStore();
export default disabledProductsStore;
