import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function GetProductDataService(productId: string) {
    // create params
    const params = {
        "module": "product",
        "process": "single",
        "productId": productId
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params
        }
    );

    if (!result || !result.data) return null;
    return result.data;
}