import { Product } from "../../market/model/ProductsResponse";
import favoriteStore from "../store/FavoriteStore";
import LikeRequestService from "./LikeRequestService";

export default function ProductLikeEdit(product: Product) {
    var ids = localStorage.getItem("ids");
    const decodedIds = ids ? JSON.parse(ids) : null;
    let liked = ThisProductLiked(product.id);

    const newIds = !decodedIds
        ? [product.id]
        : liked
            ? decodedIds.filter((id: string) => id !== product.id)
            : [...decodedIds, product.id];
    if (liked) favoriteStore.removeProduct(product);
    else favoriteStore.addProduct(product);

    localStorage.setItem('ids', JSON.stringify(newIds));
    LikeRequestService(product.id, liked ? 'remove' : 'put');
}

export function ThisProductLiked(productId: string) {
    var ids = localStorage.getItem("ids");
    const decodedIds = ids ? JSON.parse(ids) : null;
    if (!decodedIds) return false;
    return decodedIds.includes(productId);
}