import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import disabledProductsStore from "../store/DisabledProductsStore";

export default async function SetArchiveText(productId: string, archiveText: string) {
    // check values, get access token
    var accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;

    // create params
    const params = {
        "module": "admin",
        "process": "setArchive",
        "accessToken": accessToken,
        "productId": productId,
        "archiveText": archiveText
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params
        }
    );

    if (!result || !result.data) return null;

    // delete from store
    disabledProductsStore.deleteProduct(productId);
}