import ApplicationConfig from "../../../core/config/ApplicationConfig";
import myProductsStore from "../../cabinet/store/MyProductsStore";
import { Product } from "../../market/model/ProductsResponse";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { ProductAddReqest } from "../model/ProductAddRequest";

export default async function UploadProductService(data: ProductAddReqest, updatedProduct?: Product) {
    // check values, get access token
    var accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;

    // create params
    const formData = new FormData();
    formData.append('module', "product");
    formData.append('process', "upload");
    formData.append('accessToken', accessToken);
    formData.append('productId', updatedProduct ? updatedProduct.id : '');
    formData.append('data', JSON.stringify({ ...data, price: data.price.replace(/\s/g, ''), mileage: data.mileage.replace(/\s/g, ''), photo: undefined }));

    // upload images
    const imageNames: string[] = [];
    data.photo.forEach((file, index) => {
        if (!(file instanceof File || Blob)) return false;
        formData.append(`image${index}`, file);
        imageNames.push(`image${index}`);
    });
    formData.append('imageNames', JSON.stringify(imageNames));

    // request
    var result = await GlobalRequestTemplate(
        {
            type: "post",
            link: ApplicationConfig.thisProductBackendDirectory,
            formData: formData
        }
    );

    if (!result || !result.data || !result.data.product) return false;
    if (updatedProduct) myProductsStore.archiveProduct(updatedProduct.id, '1');
    return true;
}