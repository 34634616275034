import { action, makeObservable, observable } from 'mobx';
import { CustomerDataResponse } from '../model/CustomerDataResponse';

class CustomerDataStore {
    customerData: CustomerDataResponse | null = null;

    constructor() {
        makeObservable(this, {
            customerData: observable,
            saveCustomer: action,
            removeCustomer: action,
            updateUsername: action
        });
    }

    saveCustomer(value: CustomerDataResponse) {
        this.customerData = value;
    }

    removeCustomer() {
        this.customerData = null;
    }

    updateUsername(value: string) {
        if (!this.customerData) return;
        this.customerData.customer.username = value;
    }
}

const customerDataStore = new CustomerDataStore();
export default customerDataStore;
