import InputComponent from "../../shared/ui/InputComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";

const SegmentComponent = ({ fromValue, fromText, fromChange, toValue, toText, toChange, filter, maxLength }: { fromValue?: string; fromText: string; fromChange: any; toValue?: string; toText: string; toChange: any; filter?: any; maxLength?: number; }) => {
    return (
        <div style={{ display: 'flex' }}>
            <InputComponent
                initText={fromValue}
                placeholder={fromText}
                handleTextFieldValueChange={fromChange}
                filter={filter === 'digits' || filter === 'price' ? undefined : filter}
                digitFilter={filter === 'digits'}
                priceFilter={filter === 'price'}
                maxLength={maxLength}
            />
            <RetirementComponent />
            <InputComponent
                initText={toValue}
                placeholder={toText}
                handleTextFieldValueChange={toChange}
                filter={filter === 'digits' || filter === 'price' ? undefined : filter}
                digitFilter={filter === 'digits'}
                priceFilter={filter === 'price'}
                maxLength={maxLength}
            />
        </div>
    );
}

export default SegmentComponent;