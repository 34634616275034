import HomeWrapperComponent from "../../home/ui/HomeWrapperComponent";
import PageHeaderComponent from "../../home/ui/PageHeaderComponent";
import CloseIcon from "../../../core/assets/component/modal/close.svg";
import FilterIcon from "../../../core/assets/page/market/filter.svg";
import SortIcon from "../../../core/assets/page/market/sort.svg";
import TextComponent from "../../shared/ui/TextComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import FilterComponent from "./FilterComponent";
import { useMemo, useState } from "react";
import SortComponent from "./SortComponent";
import filterResponseStore from "../store/FilterStore";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import productResponseStore from "../store/ProductsStore";
import ProductBlockWrapperComponent from "./ProductBlockWrapperComponent";
import { observer } from "mobx-react-lite";
import GetProductsService from "../service/GetProductsService";
import GridComponent from "../../shared/ui/GridComponent";
import RefreshComponent from "../../shared/ui/RefreshComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";

const MarketComponent = () => {
    const { t } = useTranslation();
    const [filter, setFilter] = useState(false);
    const [sort, setSort] = useState(false);
    const [reload, setReload] = useState(false);
    function clear() {
        filterResponseStore.removeFilter(filterResponseStore.filterStore.category);
        toast(t('toast.success'), { type: "success" });
        setReload(!reload);
    }
    async function onUpdatePage(more = false) {
        if (!more) productResponseStore.reset();
        const nextPage = more ? productResponseStore.currentPage + 1 : productResponseStore.currentPage;
        await GetProductsService(filterResponseStore.filterStore, nextPage);
        productResponseStore.saveCurrentPage(nextPage);
    }
    const items = [
        { icon: CloseIcon, name: t('market.reset'), onclick: clear },
        { icon: FilterIcon, name: t('market.filter'), onclick: () => setFilter(true) },
        { icon: SortIcon, name: t('market.sort'), onclick: () => setSort(true) }
    ];
    const memoizedFilterComponent = useMemo(() => (
        <FilterComponent
            open={filter}
            toggleModal={() => setFilter(false)}
            reset={reload}
            t={t}
        />
        // eslint-disable-next-line
    ), [filter, reload]);
    return (
        <HomeWrapperComponent>
            <RefreshComponent onRefresh={onUpdatePage}>
                <PageHeaderComponent title={`
            ${filterResponseStore.filterStore.category !== ''
                        ? `${t(`filter.category.${filterResponseStore.filterStore.category}`)}: `
                        : ''}
                    ${t('market.title', { count: productResponseStore.productsData && productResponseStore.productsData.products.length > 0 ? productResponseStore.productsData.count : 0 })}`}>
                    <div style={{ display: 'flex', alignItems: 'end' }}>
                        <GridComponent>
                            {
                                items.map((field, index) => (
                                    <LinkWrapperComponent key={index} onClick={field.onclick} >
                                        <div style={{ display: 'flex', alignItems: 'center', paddingRight: index === items.length - 1 ? 0 : 20 }}>
                                            <img src={field.icon} width={20} style={{ paddingRight: 5 }} alt="market menu icon" />
                                            <TextComponent>{field.name}</TextComponent>
                                        </div>
                                    </LinkWrapperComponent>
                                ))
                            }
                        </GridComponent>
                    </div>
                </PageHeaderComponent>
                {
                    productResponseStore.productsData && <>
                        <ProductBlockWrapperComponent items={productResponseStore.productsData.products} t={t} />
                        {
                            productResponseStore.loadMore && (
                                <>
                                    <RetirementComponent />
                                    <RaisedButtonComponent
                                        placeholder={t('market.more')}
                                        onClick={() => onUpdatePage(true)}
                                    />
                                </>
                            )
                        }
                    </>
                }
                {memoizedFilterComponent}
                <SortComponent open={sort} toggleModal={() => setSort(false)} reset={reload} t={t} />
            </RefreshComponent>
        </HomeWrapperComponent>
    );
}

export default observer(MarketComponent);