import { useEffect, useRef } from "react";
import ModalComponent from "../../shared/ui/ModalComponent";
import filterResponseStore from "../store/FilterStore";
import ListComponent from "../../shared/ui/ListComponent";
import VariablesConfig from "../../../core/config/VariablesConfig";

const SortComponent = ({ open, toggleModal, reset, t }: { open: boolean; toggleModal: any; reset: boolean; t: any }) => {
    useEffect(() => {
        data.current = filterResponseStore.filterStore;
    }, [reset]);
    var data = useRef(filterResponseStore.filterStore);
    function updateData(saveFunction: any) {
        saveFunction();
        filterResponseStore.saveFilter(data.current);
        toggleModal();
    };

    return (
        <ModalComponent open={open} toggleModal={toggleModal} title={t('market.sort')}>
            <ListComponent
                items={
                    VariablesConfig.sort.map((element: any) => ({
                        label: t(`filter.sort.${element}`),
                        onClick: () => updateData(() => data.current.sort = element)
                    }))
                }
            />
        </ModalComponent>
    );
}

export default SortComponent;