import { useEffect, useState } from "react";
import RouteComponent from "./route/RouteComponent";
import "./config/LocalizationConfig";
import GlobalAuthVerifyService from "../module/authenticate/service/GlobalAuthVerifyService";
import GetProductsService from "../module/market/service/GetProductsService";
import filterResponseStore from "../module/market/store/FilterStore";
import GetCollectionProductsService from "../module/home/service/GetCollectionProductsService";
import GetFavoriteService from "../module/product/service/GetFavoriteService";

export default function App() {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    async function initialize() {
      await GetCollectionProductsService();
      await GetProductsService(filterResponseStore.filterStore);
      await GetFavoriteService();
      await GlobalAuthVerifyService();
      setInitialized(true);
    }

    initialize();
  }, []);

  if (!initialized) return null;
  return (
    <RouteComponent />
  );
}