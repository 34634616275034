export default class RouteNamespaces {
    static home: string = '/';
    static authenticate: string = '/auth';
    static cabinet: string = '/cabinet';
    static market: string = '/market';
    static item: string = '/item/:id';
    static itemWithoutParam: string = '/item';
    static favorite: string = '/favorite';
    static creditRequest: string = '/credit/request';
    static help: string = '/help';
    static my: string = '/my';
    static add: string = '/add';
    static addWithParam: string = '/add/:params';
    static admin: string = '/admin';
    static error: string = '*';
}