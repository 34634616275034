import { useEffect, useRef, useState } from "react";
import ModalComponent from "../../shared/ui/ModalComponent";
import filterResponseStore from "../store/FilterStore";
import VariablesConfig from "../../../core/config/VariablesConfig";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import SegmentComponent from "./SegmentComponent";
import ListItem from "./ListItemComponent";
import CheckItem from "./CheckItemComponent";
import InputItem from "./InputItemComponent";
import TabBarComponent from "../../shared/ui/TabBarComponent";
import SegmentListComponent from "./SegmentListComponent";
import TemplateConfig from "../../../core/config/TemplateConfig";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import TextComponent from "../../shared/ui/TextComponent";

const FilterComponent = ({ open, toggleModal, reset, t }: { open: boolean; toggleModal: any; reset: boolean; t: any }) => {
    // function
    useEffect(() => {
        data.current = filterResponseStore.filterStore;
    }, [reset]);
    var data = useRef(filterResponseStore.filterStore);
    function updateData(saveFunction: any) {
        saveFunction();
        filterResponseStore.saveFilter(data.current)
    };
    const [reload, setReload] = useState(false);
    const [showModel, setShowModel] = useState(false);

    // template
    const template = [
        { type: 'list', name: "city", value: data.current.city, items: VariablesConfig.city, onClick: (value: string) => updateData(() => data.current.city = value) },
        {
            type: 'list', name: "category", value: data.current.category, items: VariablesConfig.category, onClick: (value: string) => updateData(() => {
                data.current.category = value;
                setReload(!reload);
            })
        },
        {
            type: 'list', name: "brand", value: data.current.brand, items: data.current.category === VariablesConfig.category[0] || data.current.category === ''
                ? VariablesConfig.brands
                : data.current.category === VariablesConfig.category[1]
                    ? VariablesConfig.motoBrands
                    : VariablesConfig.commercBrands,
            showBrandIcon: data.current.category !== VariablesConfig.category[2],
            onClick: (value: string) => {
                updateData(() => data.current.brand = value);
                updateData(() => data.current.models =
                    data.current.category === VariablesConfig.category[0] || data.current.category === ''
                        ? VariablesConfig.brands.find(item => item.label === value)!.model
                        : data.current.category === VariablesConfig.category[1]
                            ? VariablesConfig.motoBrands.find(item => item.label === value)!.model
                            : VariablesConfig.commercBrands.find(item => item.label === value)!.model
                );
                setReload(!reload);
                setShowModel(true);
            }
        },
        { type: 'list', name: "model", value: data.current.model, items: data.current.models, onClick: (value: string) => updateData(() => data.current.model = value), execShow: showModel },
        {
            type: "segmentList",
            from: { type: 'list', name: "fromYear", value: data.current.yearFrom, items: VariablesConfig.year, onClick: (value: string) => updateData(() => data.current.yearFrom = value) },
            to: { type: 'list', name: "toYear", value: data.current.yearTo, items: VariablesConfig.year, onClick: (value: string) => updateData(() => data.current.yearTo = value) }
        },
        {
            type: 'segment', filter: "price", maxLength: 15,
            fromText: 'fromPrice', fromValue: data.current.priceFrom, fromOnChange: (value: string) => updateData(() => data.current.priceFrom = value.replace(/\s/g, '')),
            toText: 'toPrice', toValue: data.current.priceTo, toOnChange: (value: string) => updateData(() => data.current.priceTo = value.replace(/\s/g, ''))
        },
        { type: 'list', name: "tranmission", value: data.current.tranmission, items: VariablesConfig.transmission, onClick: (value: string) => updateData(() => data.current.tranmission = value), hide: data.current.category !== VariablesConfig.category[0] && data.current.category !== '' },
        { type: 'list', name: "body", value: data.current.body, items: VariablesConfig.body, onClick: (value: string) => updateData(() => data.current.body = value), hide: data.current.category !== VariablesConfig.category[0] && data.current.category !== '' },
        { type: 'list', name: "engine", value: data.current.engine, items: VariablesConfig.engine, onClick: (value: string) => updateData(() => data.current.engine = value), hide: data.current.category !== VariablesConfig.category[0] && data.current.category !== '' },
        { type: 'list', name: "drive", value: data.current.drive, items: VariablesConfig.drive, onClick: (value: string) => updateData(() => data.current.drive = value), hide: data.current.category !== VariablesConfig.category[0] && data.current.category !== '' },
        { type: 'list', name: "wheel", value: data.current.wheel, items: VariablesConfig.wheel, onClick: (value: string) => updateData(() => data.current.wheel = value), hide: data.current.category !== VariablesConfig.category[0] && data.current.category !== '' },
        {
            type: "segmentList",
            from: { type: 'list', name: "fromEngineSize", value: data.current.engineSizeFrom, items: VariablesConfig.engineSize, onClick: (value: string) => updateData(() => data.current.engineSizeFrom = value) },
            to: { type: 'list', name: "toEngineSize", value: data.current.engineSizeTo, items: VariablesConfig.engineSize, onClick: (value: string) => updateData(() => data.current.engineSizeTo = value) }
        },
        { type: 'input', text: 'toMileage', value: data.current.mileage, onChange: (value: string) => updateData(() => data.current.mileage = value.replace(/\s/g, '')), filter: "price", maxLength: 15 },
        { type: 'check', value: data.current.cleared, text: "cleared", onChange: (value: boolean) => updateData(() => data.current.cleared = value) },
        { type: 'check', value: data.current.trade, text: "trade", onChange: (value: boolean) => updateData(() => data.current.trade = value) },
        { type: 'check', value: data.current.exchange, text: "exchange", onChange: (value: boolean) => updateData(() => data.current.exchange = value) },
        { type: 'check', value: data.current.order, text: "order", onChange: (value: boolean) => updateData(() => data.current.order = value) },
        { type: 'check', value: data.current.owner, text: "owner", onChange: (value: boolean) => updateData(() => data.current.owner = value) },
        { type: 'check', value: data.current.projectCar, text: "projectCar", onChange: (value: boolean) => updateData(() => data.current.projectCar = value) }
    ];

    // widgets
    const TabBarBlock = () => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <TabBarComponent
                    items={[t('home.search.all'), t('home.search.old'), t('home.search.new')]}
                    init={
                        data.current.newcar
                            ? t('home.search.new')
                            : data.current.oldcar
                                ? t('home.search.old')
                                : t('home.search.all')
                    }
                    onChange={(value: string) => updateData(() => {
                        if (value === t('home.search.all')) {
                            data.current.newcar = false;
                            data.current.oldcar = false;
                        }
                        if (value === t('home.search.new')) {
                            data.current.newcar = true;
                            data.current.oldcar = false;
                        }
                        if (value === t('home.search.old')) {
                            data.current.newcar = false;
                            data.current.oldcar = true;
                        }
                    })}
                />
            </div>
        );
    }
    const SegmentItem = (field: any) => {
        return (
            <SegmentComponent
                filter={field.filter}
                maxLength={field.maxLength}
                fromValue={field.fromValue}
                fromText={t(`filter.${field.fromText}`)}
                fromChange={field.fromOnChange}
                toValue={field.toValue}
                toText={t(`filter.${field.toText}`)}
                toChange={field.toOnChange}
            />
        );
    }
    return (
        <ModalComponent
            open={open}
            toggleModal={() => { toggleModal(); setShowModel(false); }} title={t('market.filter')}
            headerAction={
                <LinkWrapperComponent onClick={toggleModal}>
                    <div style={{ backgroundColor: TemplateConfig.yellowColor, borderRadius: TemplateConfig.miniBorderRadius, padding: "4px 8px", display: 'flex', alignItems: 'center' }}>
                        <TextComponent>{t("market.search")}</TextComponent>
                    </div>
                </LinkWrapperComponent>
            }
        >
            <TabBarBlock />
            <RetirementComponent />
            {
                template.map((field, index) =>
                    <div key={index}>
                        {
                            !field.hide &&
                            <div>
                                {field.type === 'list' ? ListItem(field, t)
                                    : field.type === 'segment' ? SegmentItem(field)
                                        : field.type === 'check' ? CheckItem(field, t)
                                            : field.type === 'segmentList' ? SegmentListComponent({ t: t, fromField: field.from, toField: field.to })
                                                : InputItem(field, t)
                                }
                                {
                                    template.filter((row: any) => !row.hide).length - 1 !== index
                                    && <RetirementComponent size={10} />
                                }
                            </div>
                        }
                    </div>
                )
            }
        </ModalComponent>
    );
}

export default FilterComponent;