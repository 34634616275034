import { action, makeObservable, observable } from 'mobx';
import { Product } from '../../market/model/ProductsResponse';

class FavoriteStore {
    favoriteStore: Product[] = [];

    constructor() {
        makeObservable(this, {
            favoriteStore: observable,
            saveStore: action,
            removeStore: action,
            addProduct: action,
            removeProduct: action
        });
    }

    saveStore(value: Product[]) {
        this.favoriteStore = value;
    }

    addProduct(value: Product) {
        this.favoriteStore.push(value);
    }

    removeProduct(value: Product) {
        this.favoriteStore = this.favoriteStore.filter((item) => item.id !== value.id);
    }

    removeStore() {
        this.favoriteStore = [];
    }
}

const favoriteStore = new FavoriteStore();
export default favoriteStore;
