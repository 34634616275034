import ApplicationConfig from "../../../core/config/ApplicationConfig";
import disabledProductsStore from "../../admin/store/DisabledProductsStore";
import myProductsStore from "../../cabinet/store/MyProductsStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import customerDataStore from "../store/CustomerDataStore";

export default async function LogoutService(request = true) {
    // remove store
    customerDataStore.removeCustomer();
    myProductsStore.removeProducts();
    disabledProductsStore.removeProducts();

    // check values, get access token
    var accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;
    localStorage.removeItem("accessToken");

    if (!request) return;
    // create params
    const params = {
        "module": "account",
        "process": "logout",
        "accessToken": accessToken
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params
        }
    );

    return result;
}