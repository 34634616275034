import TemplateConfig from "../../../core/config/TemplateConfig";

const ProgressBarComponent = ({ count, current }: { count: number; current: number }) => {
    const renderSteps = () => {
        const stepElements = [];
        for (let i = 0; i < count; i++) {
            stepElements.push(
                <div style={{
                    width: '100%',
                    marginRight: count === current ? 0 : 5,
                    height: 5,
                    backgroundColor: i <= current ? TemplateConfig.yellowColor : TemplateConfig.greyColor
                }}>

                </div>
            );
        }
        return stepElements;
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between'
        }}>{renderSteps()}</div>
    );
}

export default ProgressBarComponent;