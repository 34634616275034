import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ProductBlockWrapperComponent from "../../market/ui/ProductBlockWrapperComponent";
import RefreshComponent from "../../shared/ui/RefreshComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import GetCollectionProductsService from "../service/GetCollectionProductsService";
import collectionStore from "../store/CollectionStore";
import BannerComponent from "./BannerComponent";
import HomeWrapperComponent from "./HomeWrapperComponent";
import TileComponent from "./TileComponent";

const HomeComponent = () => {
    const { t } = useTranslation();
    return (
        <HomeWrapperComponent>
            <RefreshComponent onRefresh={() => GetCollectionProductsService()}>
                <BannerComponent />
                <RetirementComponent />
                <TileComponent />
                <RetirementComponent />
                <ProductBlockWrapperComponent t={t} items={collectionStore.collectionData ?? []} />
            </RefreshComponent>
        </HomeWrapperComponent>
    );
}

export default observer(HomeComponent);