import { useEffect, useState } from "react";
import { ListModel } from "../../shared/model/ListModel";
import InputComponent from "../../shared/ui/InputComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import ModalComponent from "../../shared/ui/ModalComponent";
import ListChoiceComponentImpl from "./ListChoiceComponentImpl";

const ListChoiceComponent = ({ items, title, icon, initText, execShow }: { items: ListModel[]; title: string; icon?: string; initText: string; execShow?: boolean | null }) => {
    useEffect(() => {
        if (execShow) setListState(true);
    }, [execShow]);
    const [listState, setListState] = useState(false);
    const [text, setText] = useState(initText);
    return (
        <div style={{ width: '100%' }}>
            <LinkWrapperComponent onClick={() => setListState(true)}>
                <InputComponent
                    initText={text.length > 0 ? (text[0].toUpperCase() + text.slice(1)) : text}
                    placeholder={title}
                    disabled
                    startAdornment={icon ? <img src={icon} alt="picker in list item icon" width={22} height={22} /> : null}
                />
            </LinkWrapperComponent>
            <ModalComponent open={listState} toggleModal={() => setListState(false)} title={title} >
                <ListChoiceComponentImpl items={items} additionalAction={() => setListState(false)} setText={(value: string) => setText(value)} />
            </ModalComponent>
        </div>
    );
}

export default ListChoiceComponent;