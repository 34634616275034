import ConfirmComponent from "../../shared/ui/ConfirmComponent";

const ProductControlConfirmComponent = ({ open, toggleModal, variant, edit, remove, check, t }: { open: boolean; toggleModal: any; variant: string; edit: any; remove: any; check: any; t: any }) => {
    const action = variant === 'update' ? edit
        : variant === 'delete' ? remove
            : check;

    return ConfirmComponent(open, toggleModal, t(`product.control.${variant}`), t('product.control.text'), action, t);
}

export default ProductControlConfirmComponent;