import ModalComponent from "../../shared/ui/ModalComponent";
import TextComponent from "../../shared/ui/TextComponent";

const ReasonComponent = ({ open, toggleModal, reason, t }: { open: boolean; toggleModal: any; reason: string; t: any }) => {
    return (
        <ModalComponent open={open} toggleModal={toggleModal} title={t('admin.reason')}>
            <div>
                <TextComponent>
                    {reason}
                </TextComponent>
            </div>
        </ModalComponent>
    );
}

export default ReasonComponent;