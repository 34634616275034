import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import HomeWrapperComponent from "../../home/ui/HomeWrapperComponent";
import PageHeaderComponent from "../../home/ui/PageHeaderComponent";
import ProductBlockWrapperComponent from "../../market/ui/ProductBlockWrapperComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RefreshComponent from "../../shared/ui/RefreshComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import GetConfirmProductsService from "../service/GetConfirmProductsService";
import disabledProductsStore from "../store/DisabledProductsStore";

const DisabledProductsComponent = () => {
    const { t } = useTranslation();
    async function onUpdatePage(more = false) {
        if (!more) disabledProductsStore.reset();
        const nextPage = more ? disabledProductsStore.currentPage + 1 : disabledProductsStore.currentPage;
        await GetConfirmProductsService(nextPage);
        disabledProductsStore.saveCurrentPage(nextPage);
    }
    return (
        <HomeWrapperComponent>
            <RefreshComponent onRefresh={onUpdatePage}>
                <PageHeaderComponent title={t('admin.title')} />
                {disabledProductsStore.productsData && <>
                    <ProductBlockWrapperComponent enabledControl items={disabledProductsStore.productsData.products} t={t} />
                    {
                        disabledProductsStore.loadMore && (
                            <>
                                <RetirementComponent />
                                <RaisedButtonComponent
                                    placeholder={t('market.more')}
                                    onClick={() => onUpdatePage(true)}
                                />
                            </>
                        )
                    }
                </>}
            </RefreshComponent>
        </HomeWrapperComponent>
    );
}

export default observer(DisabledProductsComponent);