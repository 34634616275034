import { Tabs, Tab } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TemplateConfig from "../../../core/config/TemplateConfig";
import HomeWrapperComponent from "../../home/ui/HomeWrapperComponent";
import PageHeaderComponent from "../../home/ui/PageHeaderComponent";
import ProductBlockWrapperComponent from "../../market/ui/ProductBlockWrapperComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RefreshComponent from "../../shared/ui/RefreshComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import GetMyProductsService from "../service/GetMyProductsService";
import myProductsStore from "../store/MyProductsStore";

const MyProductsComponent = () => {
    // tabbar
    const tabs = ["active", "review", "archive"];
    const [barValue, setBarValue] = useState(myProductsStore.status);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        myProductsStore.setStatus(newValue);
        onUpdatePage();
        setBarValue(newValue);
    };

    // init
    const { t } = useTranslation();
    async function onUpdatePage(more = false) {
        if (!more) myProductsStore.reset();
        const nextPage = more ? myProductsStore.currentPage + 1 : myProductsStore.currentPage;
        await GetMyProductsService(nextPage);
        myProductsStore.saveCurrentPage(nextPage);
    }

    return (
        <HomeWrapperComponent>
            <RefreshComponent onRefresh={onUpdatePage}>
                <PageHeaderComponent title={t('cabinet.my')} />
                <Tabs
                    value={barValue}
                    onChange={handleChange}
                    textColor="inherit"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: TemplateConfig.blackColor
                        }
                    }}
                >
                    {
                        tabs.map((field, index) =>
                            <Tab
                                key={index}
                                style={{
                                    textTransform: 'none',
                                    fontSize: TemplateConfig.isMobile ? TemplateConfig.smallFontSize : TemplateConfig.mediumFontSize,
                                    fontWeight: TemplateConfig.mediumText,
                                    padding: TemplateConfig.isMobile ? 0 : 10
                                }}
                                value={field}
                                label={t(`cabinet.${field}`)}
                                wrapped
                            />
                        )
                    }
                </Tabs>
                <RetirementComponent />
                {
                    myProductsStore.productsData &&
                    <>
                        <ProductBlockWrapperComponent enabledControl items={myProductsStore.productsData.products.filter(
                            (item) => item.disabled === (barValue === tabs[0]
                                ? '0'
                                : barValue === tabs[1]
                                    ? '1'
                                    : '2')
                        )} t={t} />
                        {
                            myProductsStore.loadMore && (
                                <>
                                    <RetirementComponent />
                                    <RaisedButtonComponent
                                        placeholder={t('market.more')}
                                        onClick={() => onUpdatePage(true)}
                                    />
                                </>
                            )
                        }
                    </>

                }
            </RefreshComponent>
        </HomeWrapperComponent>
    );
}

export default observer(MyProductsComponent);