import InputComponent from "../../shared/ui/InputComponent";

const InputItem = (field: any, t: any) => {
    return (
        <InputComponent
            initText={field.value}
            placeholder={t(`filter.${field.text}`)}
            handleTextFieldValueChange={field.onChange}
            maxLength={field.maxLength}
            digitFilter={field.filter === 'digits'}
            priceFilter={field.filter === 'price'}
            filter={field.filter !== 'digits' && field.filter !== 'price' ? field.filter : undefined}
            multiline={field.text === 'description'}
            rows={field.text === 'description' ? '4' : undefined}
        />
    );
}

export default InputItem;