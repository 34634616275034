import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { FilterResponse } from "../model/FilterResponse";
import { ProductsResponse } from "../model/ProductsResponse";
import productResponseStore from "../store/ProductsStore";

export default async function GetProductsService(filter: FilterResponse, page = 1) {
    // create params
    const formData = new FormData();
    formData.append('module', "product");
    formData.append('process', "data");
    formData.append('filter', JSON.stringify(filter));
    formData.append('page', page.toString());

    var result = await GlobalRequestTemplate(
        {
            type: "post",
            link: ApplicationConfig.thisProductBackendDirectory,
            formData: formData
        }
    );
    if (!result || !result.data) return;

    // save store
    if ((result.data as ProductsResponse).products.length === 0) {
        productResponseStore.setLoadMore(false);
        return;
    }
    productResponseStore.saveProducts(result.data as ProductsResponse);
}