import { styled } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import { useTranslation } from "react-i18next";
import TextComponent from "../../shared/ui/TextComponent";
import { useNavigate } from "react-router-dom";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import { useEffect, useState } from "react";
// svg
import FavoriteIcon from "../../../core/assets/component/btmbar/favorite.svg";
import CabinetIcon from "../../../core/assets/component/btmbar/cabinet.svg";
import AddIcon from "../../../core/assets/component/btmbar/add.svg";
import HelpIcon from "../../../core/assets/component/btmbar/help.svg";
import HomeIcon from "../../../core/assets/component/btmbar/home.svg";
import customerDataStore from "../../authenticate/store/CustomerDataStore";

const BottomNagivationComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [active, setActive] = useState(RouteNamespaces.home);
    useEffect(() => {
        setActive(window.location.pathname);
        return () => { };
    }, [navigate]);
    if (!TemplateConfig.isMobile) return null;
    const height = 60;
    const Wrapper = styled('div')({
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: height,
        backgroundColor: TemplateConfig.whiteColor,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        alignItems: 'center'
    });
    const ComponentItem = styled('div')({
        textAlign: 'center'
    });
    const elements = [
        { text: t('pageNames.home'), icon: HomeIcon, link: RouteNamespaces.home },
        { text: t('pageNames.favorite'), icon: FavoriteIcon, link: RouteNamespaces.favorite },
        { text: t('pageNames.add'), icon: AddIcon, link: !customerDataStore.customerData ? RouteNamespaces.authenticate : RouteNamespaces.add },
        { text: t('pageNames.help'), icon: HelpIcon, link: RouteNamespaces.help },
        { text: t('pageNames.cabinet'), icon: CabinetIcon, link: !customerDataStore.customerData ? RouteNamespaces.authenticate : RouteNamespaces.cabinet }
    ];
    if (RouteNamespaces.itemWithoutParam === window.location.pathname.substring(0, 5)) return <RetirementComponent size={height} />;
    return (
        <div>
            <RetirementComponent size={height} />
            <Wrapper>
                {elements.map((field, index) => (
                    <ComponentItem key={index} onClick={() => navigate(field.link)}>
                        <img src={field.icon} alt="bar item icon" width={20} height={20} />
                        <TextComponent type={'extraSmall'} weight={active === field.link ? TemplateConfig.semiboldText : undefined}>
                            {field.text}
                        </TextComponent>
                    </ComponentItem>
                ))}
            </Wrapper>
        </div>
    );
}

export default BottomNagivationComponent;