import ApplicationConfig from "../../../core/config/ApplicationConfig";
import CalculateInitPaymentService from "./CalculateInitPaymentService";

export default function CalculateCreditService({ price, init, month }: { price: string; init: string; month: string }) {
    const minInitPayment = parseInt(CalculateInitPaymentService({ price: price }).toString());
    const convertedPrice = Number(price);
    const convertedInit = Number(init);
    const convertedMonth = Number(month);
    if (convertedInit < minInitPayment || convertedInit > convertedPrice || convertedPrice < 100 || convertedInit < 100) return 0;
    let calculated = convertedPrice + (convertedPrice * ApplicationConfig.creditProcent);
    calculated = calculated - convertedInit;
    return Number(calculated / convertedMonth);
}