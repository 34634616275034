import RetirementComponent from "../../shared/ui/RetirementComponent";
import ListItem from "./ListItemComponent";

const SegmentListComponent = ({ t, fromField, toField }: { t: any, fromField: any, toField: any }) => {
    return (
        <div style={{ display: 'flex' }}>
            {ListItem(fromField, t)}
            <RetirementComponent />
            {ListItem(toField, t)}
        </div>
    );
}

export default SegmentListComponent;