import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import customerDataStore from "../../authenticate/store/CustomerDataStore";

export default async function UpdateDataService(username: string, t: any) {
    // check values, get access token
    var accessToken = localStorage.getItem("accessToken");
    if (!accessToken) return;

    // create params
    const params = {
        "module": "account",
        "process": "updateData",
        "accessToken": accessToken,
        "username": username
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params
        }
    );
    if (!result || !result.data) return;

    // result
    customerDataStore.updateUsername(username);
    toast(t("toast.success"), { type: "success" });
}