import { Switch } from "@mui/material";
import { useState } from "react";
import TemplateConfig from "../../../core/config/TemplateConfig";
import TextComponent from "./TextComponent";

const CheckComponent = ({ init = false, onChange, title }: { init?: boolean; onChange: any; title?: string }) => {
    const [value, setValue] = useState(init);
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextComponent>{title}</TextComponent>
            <Switch
                checked={value}
                onChange={() => { setValue(!value); onChange(!value) }}
                inputProps={{ 'aria-label': 'controlled' }}
                color="warning"
            />
            <style>
                {
                    `
                        .Mui-checked {
                            color: ${TemplateConfig.yellowColor} !important;
                        }
                        .Mui-checked+.MuiSwitch-track {
                            background-color: ${TemplateConfig.codGreyColor} !important;
                        }
                    `
                }
            </style>
        </div>
    );
}

export default CheckComponent;