import TemplateConfig from "../../../core/config/TemplateConfig";
import ModalComponent from "./ModalComponent";
import RaisedButtonComponent from "./RaisedButtonComponent";
import RetirementComponent from "./RetirementComponent";

const ConfirmComponent = (open: boolean, toggleModal: any, title: string, text: string, onSubmit: any, t: any) => {
    return (
        <ModalComponent open={open} toggleModal={toggleModal} title={title}>
            {text}
            <RetirementComponent />
            <div style={{ display: 'flex', gap: 10 }}>
                <RaisedButtonComponent
                    placeholder={t('global.no')}
                    backgroundColor={TemplateConfig.greyColor}
                    onClick={toggleModal}
                />
                <RaisedButtonComponent
                    placeholder={t('global.yes')}
                    onClick={onSubmit}
                />
            </div>
        </ModalComponent>
    );
}

export default ConfirmComponent;