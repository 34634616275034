import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InputComponent from "../../shared/ui/InputComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import LogotypeHeader from "../../../core/assets/shared/logo-header.svg";
import TextComponent from "../../shared/ui/TextComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import { useNavigate } from "react-router-dom";
import AuthorizationService from "../service/AuthorizationService";
import { toast } from "react-toastify";
import RouteNamespaces from "../../../core/route/RouteNamespaces";

const AuthComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // telephone
    const telephoneField = useRef('');
    const onTelephoneChange = (newValue: string) => { telephoneField.current = newValue };

    // code
    const codeField = useRef('');
    const onCodeChange = (newValue: string) => { codeField.current = newValue };
    const [codeSended, codeSendedChange] = useState(false);

    // timer
    const [time, setTime] = useState(0);
    useEffect(() => {
        if (time > 0) {
            const timerId = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);

            return () => clearInterval(timerId);
        }
    }, [time]);

    // function
    async function initAuthorize() {
        if (telephoneField.current.length < 15 || (codeSended && codeField.current.length !== 4)) {
            toast(t('toast.dataError'), { type: "error" });
            return;
        }

        var result = await AuthorizationService({
            telephone: telephoneField.current.replace(/\D/g, ''),
            navigate: navigate,
            code: codeSended ? codeField.current : ''
        });

        if (!codeSended && result) { codeSendedChange(true); setTime(60); }
    }

    async function resendCode() {
        if (codeSended && time > 0) {
            toast(t('toast.timerError'), { type: "error" });
            return;
        }

        var result = await AuthorizationService({
            telephone: telephoneField.current.replace(/\D/g, ''),
            navigate: navigate,
            code: ''
        });

        if (result) setTime(60);
    }

    return (
        <div style={{ textAlign: 'center' }}>
            <LinkWrapperComponent onClick={() => navigate(RouteNamespaces.home)}>
                <img src={LogotypeHeader} alt="logotype" width={150} />
            </LinkWrapperComponent>
            <RetirementComponent size={30} />
            <TextComponent type='default'>
                {t('pageNames.authenticate')}
            </TextComponent>
            <RetirementComponent />
            <InputComponent
                placeholder={t('authenticate.telephone')}
                handleTextFieldValueChange={onTelephoneChange}
                mobileFilter
            />

            {
                codeSended
                    ? <>
                        <RetirementComponent size={10} />
                        <InputComponent
                            placeholder={t('authenticate.code')}
                            handleTextFieldValueChange={onCodeChange}
                            maxLength={4}
                            digitFilter
                        />
                        <RetirementComponent size={10} />


                        <TextComponent>
                            <LinkWrapperComponent onClick={resendCode}>
                                {t('authenticate.resend')}: {time}сек
                            </LinkWrapperComponent>
                        </TextComponent>
                    </>
                    : null
            }

            <RetirementComponent />
            <RaisedButtonComponent
                onClick={initAuthorize}
                placeholder={t('global.next')}
            />
        </div>
    );
}

export default AuthComponent;