import ApplicationConfig from "../../../core/config/ApplicationConfig";
import ListChoiceComponent from "../../product/ui/ListChoiceComponent";

const ListItem = (field: any, t: any) => {
    return (
        <ListChoiceComponent
            execShow={field.execShow}
            initText={field.value.length < 1 ? field.value : field.name === 'brand' || field.name === 'model' || field.name === 'fromYear' || field.name === 'toYear' || field.name === 'fromEngineSize' || field.name === 'toEngineSize' || field.name === 'engineSize' || field.name === 'year' ? field.value : t(`filter.${field.name}.${field.value}`)}
            icon={field.icon}
            title={field.name === 'brand' || field.name === 'model' || field.name === 'fromYear' || field.name === 'toYear' || field.name === 'fromEngineSize' || field.name === 'toEngineSize' || field.name === 'engineSize' || field.name === 'year' ? t(`filter.${field.name}`) : t(`filter.${field.name}.name`)}
            items={
                field.items === null
                    ? []
                    : field.items.map((element: any) => ({
                        icon: field.name === 'brand' && field.showBrandIcon
                            ? `${ApplicationConfig.thisProductImagesDirectory}brands/thumb/${element.label.toLowerCase()}.png`
                            : undefined,
                        label: field.name === 'brand' || field.name === 'model' || field.name === 'fromYear' || field.name === 'toYear' || field.name === 'fromEngineSize' || field.name === 'toEngineSize' || field.name === 'engineSize' || field.name === 'year'
                            ? field.name === 'brand'
                                ? element.label
                                : element
                            : t(`filter.${field.name}.${element}`),
                        onClick: () => field.onClick(field.name === 'brand' ? element.label : element)
                    }))
            }
        />
    );
}

export default ListItem;