import TemplateConfig from "../../../core/config/TemplateConfig";
import ModalComponent from "../../shared/ui/ModalComponent";
import TextComponent from "../../shared/ui/TextComponent";

const ProductDescriptionComponent = (t: any, items: any, open: boolean, toggleModal: any, title: string,) => {
    return (
        <ModalComponent open={open} toggleModal={toggleModal} title={title}>
            {
                items.map((field: any, index: number) =>
                    <div key={index}
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            marginBottom: index === items.length - 1 ? 'none' : 10,
                        }}
                    >
                        <TextComponent weight={TemplateConfig.lightweightText}>{field.title}</TextComponent>
                        <TextComponent weight={TemplateConfig.mediumText}>{field.show ? field.value : t('product.empty')}</TextComponent>
                    </div>
                )
            }
        </ModalComponent>
    );
}

export default ProductDescriptionComponent;