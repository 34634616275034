import { styled } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";

const CardComponent = ({ width, height, children, aspectRatio, margin = false, padding = false, backgroundColor = TemplateConfig.whiteColor, customPadding }: { width?: string | number; height?: string | number; children: any; margin?: boolean; aspectRatio?: number; padding?: boolean; backgroundColor?: string; customPadding?: number }) => {
    const Wrapper = styled('div')(() => ({
        backgroundColor: backgroundColor,
        width: width,
        borderRadius: TemplateConfig.borderRadius,
        heigth: height ?? 'auto',
        aspectRatio: aspectRatio,
        padding: padding ? customPadding ?? TemplateConfig.defaultPadding : 'none'
    }));

    return (
        <Wrapper>
            <div style={{ margin: margin ? TemplateConfig.defaultPadding : 'none', height: '100%', minWidth: '100%' }}>
                {children}
            </div>
        </Wrapper>
    );
}

export default CardComponent;