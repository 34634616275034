import ApplicationConfig from "../../../core/config/ApplicationConfig";
import { Product } from "../../market/model/ProductsResponse";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import collectionStore from "../store/CollectionStore";

export default async function GetCollectionProductsService() {
    // create params
    const params = {
        "module": "product",
        "process": "collection"
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendDirectory,
            params: params
        }
    );

    if (!result || !result.data || !result.data.products) return null;
    collectionStore.saveCollection(result.data.products as Product[]);
    collectionStore.saveCount(result.data.carCount, result.data.motoCount, result.data.commercCount);
}