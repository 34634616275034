import { CircularProgress } from "@mui/material";
import PullToRefresh from "react-simple-pull-to-refresh";
import TemplateConfig from "../../../core/config/TemplateConfig";

const RefreshComponent = ({ children, onRefresh }: { children: any; onRefresh: any }) => {
    return (
        <PullToRefresh
            onRefresh={onRefresh}
            pullingContent={<p></p>}
            refreshingContent={<CircularProgress style={{ color: TemplateConfig.blackColor, marginTop: 20, width: 25, height: 25 }} />}
        >
            {children}
        </PullToRefresh>
    );
}

export default RefreshComponent;