import { useRef, useState } from "react";
import { ListModel } from "../../shared/model/ListModel";
import InputComponent from "../../shared/ui/InputComponent";
import ListComponent from "../../shared/ui/ListComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import SearchIcon from "../../../core/assets/shared/search.svg";

const ListChoiceComponentImpl = ({ items, additionalAction, setText }: { items: ListModel[]; additionalAction: any; setText: any }) => {
    const [localItems, setLocalItems] = useState(items);
    // search
    const searchField = useRef('');
    const onSearchChange = (newValue: string) => {
        searchField.current = newValue;
        setLocalItems(items.filter(item => item.label.toLocaleLowerCase().includes(searchField.current.toLocaleLowerCase())))
    };

    return (
        <>
            <InputComponent
                placeholder="Поиск"
                handleTextFieldValueChange={onSearchChange}
                startAdornment={<img src={SearchIcon} width={22} height={22} alt="picker in list item icon" />}
            />
            <RetirementComponent />
            <ListComponent items={localItems} additionalAction={additionalAction} setText={setText} />
        </>
    );
}

export default ListChoiceComponentImpl;