import CardComponent from "../../shared/ui/CardComponent";
import GridComponent from "../../shared/ui/GridComponent";
import TextComponent from "../../shared/ui/TextComponent";
import TemplateConfig from "../../../core/config/TemplateConfig";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import { useNavigate } from "react-router-dom";
import filterResponseStore from "../../market/store/FilterStore";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import { useTranslation } from "react-i18next";
// svg
import CarIcon from "../../../core/assets/component/tile/car.png";
import CommercIcon from "../../../core/assets/component/tile/commerc.png";
import MotoIcon from "../../../core/assets/component/tile/moto.png";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import VariablesConfig from "../../../core/config/VariablesConfig";
import collectionStore from "../store/CollectionStore";

const TileComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    function updateData(type: string) {
        const filters = { ...VariablesConfig.defaultFilter };
        filters.category = type;
        filterResponseStore.saveFilter(filters);
        navigate(RouteNamespaces.market);
    };

    const items = [
        { text: t('filter.category.light-auto'), icon: CarIcon, onClick: () => updateData(VariablesConfig.category[0]), count: collectionStore.carCount },
        { text: t('filter.category.moto'), icon: MotoIcon, onClick: () => updateData(VariablesConfig.category[1]), count: collectionStore.motoCount },
        { text: t('filter.category.commerc'), icon: CommercIcon, onClick: () => updateData(VariablesConfig.category[2]), count: collectionStore.commercCount }
    ];
    const TileItem = (item: any, index: number) => {
        return (
            <LinkWrapperComponent key={index} onClick={item.onClick}>
                <CardComponent aspectRatio={TemplateConfig.isMobile ? 0.8 : 2.7} padding customPadding={TemplateConfig.miniPadding} backgroundColor={TemplateConfig.yellowColor}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        height: '100%'
                    }}>
                        <div style={{ height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={item.icon} alt="item icon" width="45" />
                        </div>
                        <RetirementComponent size={10} />
                        <TextComponent type={TemplateConfig.isDesktop ? 'default' : 'extraSmall'} weight={TemplateConfig.mediumText}>
                            {item.text}
                        </TextComponent>
                        <TextComponent weight={TemplateConfig.mediumText} type={TemplateConfig.isDesktop ? 'default' : 'extraSmall'}>{item.count}</TextComponent>
                    </div>
                </CardComponent>
            </LinkWrapperComponent>
        );
    }
    return (
        <GridComponent xs={4} sm={4}>
            {items.map((field, index) => TileItem(field, index))}
        </GridComponent>
    );
};

export default TileComponent;