import { useTranslation } from "react-i18next";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import TemplateConfig from "../../../core/config/TemplateConfig";
import CardComponent from "../../shared/ui/CardComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import TextComponent from "../../shared/ui/TextComponent";

const FooterComponent = () => {
    const { t } = useTranslation();
    if (TemplateConfig.isMobile) return null;
    const items = [
        { title: t('footer.title1'), value: `${t('footer.text1')} ${t('footer.text2')}` },
        { title: null, value: `${t('footer.text3')} ${t('footer.text4')}` },
        { title: null, value: t('footer.text5') },
        {
            title: "",
            value: `${ApplicationConfig.applicationName} © 2024`
        }
    ];
    return (
        <div>
            <CardComponent backgroundColor={TemplateConfig.darkGreyColor} padding>
                {
                    items.map((field, index) => (
                        <div key={index}>
                            {
                                field.title
                                    ? <>
                                        <TextComponent type="boldMedium">{field.title}</TextComponent>
                                        <RetirementComponent size={5} />
                                    </>
                                    : null
                            }
                            <TextComponent type="small">{field.value} </TextComponent>
                            {
                                index === items.length - 1
                                    ? null
                                    : <RetirementComponent />
                            }
                        </div>
                    ))
                }
            </CardComponent>
            <RetirementComponent size={40} />
        </div>
    );
};

export default FooterComponent;