import { Grid } from "@mui/material";

const GridComponent = ({ xs, sm, md, children, rowSpacing = 1, columnSpacing = 1 }: { xs?: number; sm?: number; md?: number; children: any[]; rowSpacing?: number; columnSpacing?: number }) => {
    return (
        <Grid container rowSpacing={rowSpacing} columnSpacing={columnSpacing}>
            {
                children.map((field, index) => (
                    <Grid key={index} item xs={xs} sm={sm} md={md}>
                        {field}
                    </Grid>
                ))
            }
        </Grid>
    );
}

export default GridComponent;