import { List, ListItem, ListItemIcon, ListItemSecondaryAction } from "@mui/material";
import { ListModel } from "../model/ListModel";
import TextComponent from "./TextComponent";

const ListComponent = ({ items, additionalAction, setText }: { items: ListModel[]; additionalAction?: any; setText?: any }) => {
    return (
        <List style={{ paddingTop: 0 }}>
            {
                items.map((field, index) => (
                    <ListItem key={index} button style={{ paddingLeft: 0 }} onClick={() => {
                        field.onClick();
                        if (setText) setText(field.label);
                        if (additionalAction) additionalAction();
                    }}>
                        {
                            field.icon
                                ? <ListItemIcon>
                                    <img src={field.icon} alt="list item icon" width={28} />
                                </ListItemIcon>
                                : null
                        }
                        <TextComponent>{field.label.length > 0 ? (field.label[0].toUpperCase() + field.label.slice(1)) : field.label}</TextComponent>
                        {
                            field.action
                                ? <ListItemSecondaryAction style={{ position: 'absolute', right: 0 }}>
                                    {field.action}
                                </ListItemSecondaryAction>
                                : null
                        }
                    </ListItem>
                ))
            }
        </List>
    );
}

export default ListComponent;